import React from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

import { NavLink } from "react-router-dom";

import plusIcon from "../../assets/icon-plus-white.svg";

function MyMenuHeader() {

  return (
    <>
      <Row className="my-menu">
        <Col><NavLink to="/mijn" exact>dashboard</NavLink></Col>
        <Col><NavLink to="/mijn/orders">zendingen</NavLink></Col>
        <Col><NavLink to="/mijn/facturen">facturen</NavLink></Col>
        <Col><NavLink to="/mijn/adresboek">adresboek</NavLink></Col>
        <Col><NavLink to="/mijn/profiel">profiel</NavLink></Col>
        <Col><NavLink to="/logout">uitloggen</NavLink></Col>
      </Row>
      <NavLink to="/mijn/orders/nieuw" exact className="shipment-floater">
        <img src={plusIcon} className="shipment-floater__img" alt="+" />
        <span className="shipment-floater__txt">nieuwe zending</span>
      </NavLink>
    </>
  );
}

export default MyMenuHeader;
