import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { Helmet } from "react-helmet";

import "../My.scss";
import { NavLink } from "react-router-dom";

import MyService from "../../../services/MyService";
import MyMenuHeader from "../../../components/My/MyMenuHeader";


function PageMyOrdersLabels() {
  const [orders, setOrders] = useState([]);
  const [dataLoading, setDataLoading] = useState(true);

  useEffect(() => {
    MyService.getOrderList({today: true}).then((orders) => {setOrders(orders); setDataLoading(false);});
  }, [])

  const CompactAddressBlock = ({ address }) => (
    address ? 
      <>
        <strong>
          {address.companyName ? address.companyName : `${address.firstName} ${address.lastName}`}
        </strong>
        <br />
        {`${address.street} ${address.housenumber}${
          address.housenumberAddition || ""
        }`}
        <br />
        {`${address.postcode} ${address.city}`}
      </> : <> </>
  );
  
  return (
    <div>
      <Helmet>
        <title>
          Zendingen - Mijn Verzenden - één verzendplatform voor grote en kleine pakketten
        </title>
        <link rel="canonical" href="https://www.verzenden.nl/mijn/adresboek" />
        <meta
          name="description"
          content="Wat wil je vandaag verzenden? Kies uit onze betrouwbare verzendpartners!"
        />
      </Helmet>

      <Container className="container-content container-my mt-5">
        <MyMenuHeader />
        <Row>
          <Col xs={12} lg={9}>
            <h1 className="d-inline mr-3">Vandaag aangemaakte zendingen</h1>
            <NavLink className="headerlink" to="/mijn/orders">&raquo; bekijk alle zendingen</NavLink>
          </Col>
          {orders && orders.todaysLabels && (
            <Col xs={12} lg={3} className="mt-4 mt-lg-0">
              <a href={`${process.env.CONF_API_HOST}${orders.todaysLabels}`} 
                className="pr-2" 
                target="_blank" 
                rel="noreferrer">
                <button className="btn btn-primary w-100">download labels</button>
              </a>
            </Col>)}
        </Row>
       
        <Row className="mt-4">
          <Col>
            {/* {addresses && JSON.parse(addresses)} */}
            <div className="my-table__wrapper">
              <table className="my-table">
                <tr className="my-table__header">
                  <th width="20%">zending</th>
                  <th width="30%">afleveradres</th>
                  <th width="30%">afhaaladres</th>
                  <th width="20%">label</th>
                  {/* <th width="10%">acties</th> */}
                </tr>
                {dataLoading && <tr>
                  <td><span className="my-table__skeleton"></span></td>
                  <td><span className="my-table__skeleton"></span></td>
                  <td><span className="my-table__skeleton"></span></td>
                  <td><span className="my-table__skeleton"></span></td>
                </tr>}
                {orders.orders && orders.orders.map((order) =>
            
                  <tr>
                    <td>
                      <NavLink to={`/mijn/orders/${order.id}`} className="cta cta--large">{order.number}</NavLink><br />
                      <small>{order.reference}</small>
                    </td>
                    <td><NavLink to={`/mijn/orders/${order.id}`}><CompactAddressBlock address={order.dropoffAddress} /> </NavLink></td>
                    <td><NavLink to={`/mijn/orders/${order.id}`}><CompactAddressBlock address={order.pickupAddress} /> </NavLink></td>
                    <td><a href={`${process.env.CONF_API_HOST}${order.labelUri}`} target="_new" className="cta">
                      download label
                    </a></td>

                  </tr>)}
              </table>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default PageMyOrdersLabels;
