import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { Helmet } from "react-helmet";

import "../My.scss";

import MyService from "../../../services/MyService";
import MyMenuHeader from "../../../components/My/MyMenuHeader";
import Loader from "../../../components/Loader/Loader";

import { NavLink, useHistory } from "react-router-dom";


function PageMyOrdersReturn() {
  const [loading, setLoading] = useState(true);

  const history = useHistory();

  const getStatus = async () => {
    setLoading(true);
    const orders = await MyService.getOrderList({accepted: false});
    if(!(orders && orders.orders.length)) {
      history.push("/mijn/betalen/labels");
    }
    setLoading(false);
  }


  useEffect(() => {getStatus() }, [])


  return (
    <div>
      <Helmet>
        <title>
          Betalen - Mijn Verzenden - één verzendplatform voor grote en kleine pakketten
        </title>
        <link rel="canonical" href="https://www.verzenden.nl/mijn/adresboek" />
        <meta
          name="description"
          content="Wat wil je vandaag verzenden? Kies uit onze betrouwbare verzendpartners!"
        />
      </Helmet>

      <Container className="container-content container-my mt-5">
        <MyMenuHeader />

        {loading ? <Loader /> : 
          <>
            <Row>
              <Col xs={12} className="text-center">
                <h1>Betaling mislukt</h1>
              </Col>
            </Row>
            <Row >
              <Col xs={12} className="text-center mt-5">
                <p>
                Het lijkt er op dat de betaling is mislukt.<br /><br />
                  <NavLink to="/mijn/betalen" exact className="btn btn-primary">Terug naar betaling</NavLink>
                </p>
              </Col>
            </Row>

          </>
        }
      </Container>
    </div>
  );
}

export default PageMyOrdersReturn;
