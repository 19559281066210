import React, { useState, useEffect } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { Helmet } from "react-helmet";
// import dataLayer from 'analytics-datalayer';


import "./OrderFlow.scss";
import Col from "react-bootstrap/Col";
import Axios from "axios";
import { useParams, Link } from "react-router-dom";
import Loader from "../../components/Loader/Loader";
// import useGTM from '@elgorditosalsero/react-gtm-hook'
var GTM = require('gtm-event');



function PageOrderPaymentReturn() {
  const [orderStatus, setOrderStatus] = useState({ loading: true });

  const { orderId } = useParams();
  // const { sendDataToGTM } = useGTM()


  const getOrderStatus = async () => {
    // try {
    const response = await Axios.get(
      `${process.env.CONF_API_BASE}/order/${orderId}`
    );

    if(response.status === 200 && response.data && response.data.paid) {
      GTM.event("conversie", 'pay', "betaald");
    }
    setOrderStatus(
      (response.status === 200 && { ...response.data, loading: false }) || {
        loading: false,
        error: true,
      }
    );

  };

  useEffect(() => {
    getOrderStatus();
  }, []);

  return (
    <div>
      <Helmet>
        <title>
          Betaling - verzenden.nl: één verzendplatform voor grote en kleine
          pakketten
        </title>
        <link
          rel="canonical"
          href="https://www.verzenden.nl/betaling/controleer/"
        />
      </Helmet>

      <Container className="mt-3 pt-5 ">
        <div className="container-content">
          <h1>Betaling</h1>
          {orderStatus.loading && <Loader />}

          {orderStatus && orderStatus.id && !orderStatus.paid && (
            <Row>
              <Col xs={12}>
                <div
                  className="alert alert-warning alert-auto mt-3 w-100"
                  role="alert"
                >
                  De betaling is helaas mislukt!
                </div>
              </Col>

              <Col xs={12} className="mt-5 text-center">
                Om de bestelling alsnog af te ronden, kan je de betaling opnieuw
                proberen.
              </Col>
              <Col xs={12} className="mt-4 text-center">
                <Link
                  className="btn btn-primary"
                  to={`/verzenden/betaling/${orderId}`}
                >
                  terug naar betaling
                </Link>
              </Col>
            </Row>
          )}

          {orderStatus && orderStatus.id && orderStatus.paid && (
            <Row>
              <Col xs={12}>
                <div
                  className="alert alert-info alert-auto mt-3 w-100"
                  role="alert"
                >
                  De betaling is succesvol verwerkt!
                </div>

                <Col xs={12} className=" mt-5 mb-2">
                  <h2>Klaar om te verzenden?</h2>
                </Col>
                <Col>
                  Bekijk hier eerst de{" "}
                  <a href="/docs/verpakkingsinstructies.pdf" target="_blank">verpakkingsinstructies en checklist</a>.
                </Col>
                <Col xs={12} className="mt-5 text-center">
                  <a
                    href={`${process.env.CONF_API_HOST}${orderStatus.labelUri}`}
                    target="_blank"
                    rel="noreferrer"
                    className="btn btn-primary"
                  >
                    download verzendlabel
                  </a>
                </Col>
              </Col>
            </Row>
          )}
        </div>
      </Container>
    </div>
  );
}

export default PageOrderPaymentReturn;
