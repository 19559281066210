import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { Helmet } from "react-helmet";

import "./My.scss";
import { NavLink } from "react-router-dom";

import MyService from "../../services/MyService";
import MyMenuHeader from "../../components/My/MyMenuHeader";


function PageMyAddressbook() {
  const [addresses, setAddresses] = useState([]);
  const [dataLoading, setDataLoading] = useState(true);

  useEffect(() => {
    MyService.getAddressbook().then((data) => {setAddresses(data.addresses); setDataLoading(false);});
  }, [])

  return (
    <div>
      <Helmet>
        <title>
          Adresboek - Mijn Verzenden - één verzendplatform voor grote en kleine pakketten
        </title>
        <link rel="canonical" href="https://www.verzenden.nl/mijn/adresboek" />
        <meta
          name="description"
          content="Wat wil je vandaag verzenden? Kies uit onze betrouwbare verzendpartners!"
        />
      </Helmet>

      <Container className="container-content container-my mt-5">
        <MyMenuHeader />
        <Row>
          <Col>
            <h1 className="d-inline mr-3">Adresboek</h1>
            <NavLink className="headerlink" to="/mijn/adresboek/nieuw">voeg adres toe</NavLink>
          </Col>
        </Row>
        <Row className="mt-4">
          <Col>
            {/* {addresses && JSON.parse(addresses)} */}
            <div className="my-table__wrapper">
              <table className="my-table">
                <tr className="my-table__header">
                  <th width="35%">Naam</th>
                  <th width="30%">Adres</th>
                  <th width="25%">Plaats</th>
                  <th width="10%">Acties</th>
                </tr>
                {dataLoading && <tr>
                  <td><span className="my-table__skeleton"></span></td>
                  <td><span className="my-table__skeleton"></span></td>
                  <td><span className="my-table__skeleton"></span></td>
                  <td><span className="my-table__skeleton"></span></td>
                </tr>}
                {addresses && addresses.map((address) =>
            
                  <tr>
                    <td><NavLink to={`/mijn/adresboek/${address.id}`}>{address.companyName ?
                      ( <><strong>{address.companyName} </strong>
                        {` - ${address.firstName || ''} ${address.lastName || ''}`}
                      </>
                      ) : 
                      <strong>{`${address.firstName || ''} ${address.lastName || ''}`}</strong>}
                    </NavLink>
                    </td>
                    <td><NavLink to={`/mijn/adresboek/${address.id}`}>{`${address.street} ${address.housenumber}${address.housenumberAddition || ''}`}</NavLink></td>
                    <td><NavLink to={`/mijn/adresboek/${address.id}`}>{address.city}</NavLink></td>
                    <td><NavLink to={`/mijn/adresboek/${address.id}`} className="cta">bekijk</NavLink></td>
                  </tr>)}
              </table>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default PageMyAddressbook;
