import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import HeaderMenuComponent from "./components/header/HeaderMenuComponent";
import PageHome from "./pages/PageHome";
import "./App.scss";
import PageOrderParcel from "./pages/order/PageOrderParcel";
import PageOrderDeliveryAddress from "./pages/order/PageOrderDeliveryAddress";
import PageOrderBillingAddress from "./pages/order/pageOrderBillingAddress";
import FooterComponent from "./components/footer/FooterComponent";
import PageOrderOverview from "./pages/order/PageOrderOverview";
import PageOrderPayment from "./pages/order/PageOrderPayment";
import PageOrderPaymentReturn from "./pages/order/PageOrderPaymentReturn";
import FaqList from "./pages/faq/FaqList";

import ScrollToTop from "react-router-scroll-top";
import PageOrderPickupAddress from "./pages/order/pageOrderPickupAddress";
import PageTrackTrace from "./pages/tracking/PageTrackTrace/PageTrackTrace";
import PageLogin from "./pages/login/PageLogin";
import PageContact from "./pages/faq/PageContact";
import { GTMProvider } from '@elgorditosalsero/react-gtm-hook'
import PageMyDashboard from "./pages/My/PageMyDashboard";
import PageMyAddressbook from "./pages/My/PageMyAddressbook";
import PageMyAddressbookCreate from "./pages/My/PageMyAddressbookCreate";
import PageMyAddressbookView from "./pages/My/PageMyAddressbookView";
import PageMyOrders from "./pages/My/Order/PageMyOrders";
import PageMyOrdersView from "./pages/My/Order/PageMyOrdersView";
import PageRegister from "./pages/Register/PageRegister";
import PageActivateAccount from "./pages/Register/PageActivateAccount";
import PageMyProfile from "./pages/My/Profile/PageMyProfile";
import PageLogout from "./pages/login/PageLogout";
import PageMyOrdersCreate from "./pages/My/Order/PageMyOrdersCreate";
import PageMyInvoices from "./pages/My/Invoice/PageMyInvoices";
import PageMyOrdersPay from "./pages/My/Order/PageMyOrdersPay";
import PageMyOrdersReturn from "./pages/My/Order/PageMyOrdersReturn";
import PageMyOrdersLabels from "./pages/My/Order/PageMyOrdersLabels";

import * as Sentry from "@sentry/react";


function App() {

  const gtmParams = { id: 'GTM-TTBCZF4' }

  return (
    <GTMProvider state={gtmParams}>

      <Router>
        <ScrollToTop>
      
          <div className="verzenden-app">
            <HeaderMenuComponent />

            <Switch>
              <Route exact path="/" component={PageHome} />

              {/* ORDER FLOW */}
              <Route
                exact
                path="/verzenden"
                component={() => <Redirect to="/verzenden/pakket" />}
              />

              <Route exact path="/verzenden/pakket" component={PageOrderParcel} />
              <Route exact path="/verzenden/adres" component={PageOrderDeliveryAddress} />
              <Route exact path="/verzenden/factuuradres" component={PageOrderBillingAddress} />
              <Route exact path="/verzenden/ophaaladres" component={PageOrderPickupAddress} />
              <Route exact path="/verzenden/controleer" component={PageOrderOverview} />
              <Route exact path="/verzenden/betaling/:orderId" component={PageOrderPayment} />

              <Route exact path="/betaling/controleer/:orderId" component={PageOrderPaymentReturn} />
              <Route exact path="/trace/:barcode/:postcode" component={PageTrackTrace} />
              <Route exact path="/faq" component={FaqList} />
              <Route exact path="/contact" component={PageContact} />

              <Route exact path="/login" component={PageLogin} /> 
              <Route exact path="/logout" component={PageLogout} /> 
              <Route exact path="/registreer" component={PageRegister} /> 
              <Route exact path="/registreer/activeer/:guid/:activationCode?" component={PageActivateAccount} /> 

              <Route exact path="/mijn" component={PageMyDashboard} />
              <Route exact path="/mijn/adresboek" component={PageMyAddressbook} />
              <Route exact path="/mijn/adresboek/nieuw" component={PageMyAddressbookCreate} />
              <Route exact path="/mijn/adresboek/:guid" component={PageMyAddressbookView} />

              <Route exact path="/mijn/orders" component={PageMyOrders} />
              <Route exact path="/mijn/orders/nieuw" component={PageMyOrdersCreate} />
              <Route exact path="/mijn/orders/:guid" component={PageMyOrdersView} />

              <Route exact path="/mijn/betalen" component={PageMyOrdersPay} />
              <Route exact path="/mijn/betalen/controleer" component={PageMyOrdersReturn} />
              <Route exact path="/mijn/betalen/labels" component={PageMyOrdersLabels} />

              <Route exact path="/mijn/facturen" component={PageMyInvoices} />
              <Route exact path="/mijn/facturen/:guid" component={PageMyOrdersView} />
            
              <Route exact path="/mijn/profiel" component={PageMyProfile} />
              
            </Switch>
          </div>
          <FooterComponent />
        </ScrollToTop>
      </Router>
    </GTMProvider>
  );
}

export default Sentry.withProfiler(App);
