import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { Helmet } from "react-helmet";

import "./My.scss";

import MyService from "../../services/MyService";
import MyMenuHeader from "../../components/My/MyMenuHeader";
import { NavLink } from "react-router-dom";
import Loader from "../../components/Loader/Loader";


function PageMyDashboard() {
  const [dashboardData, setDashboardData] = useState({})
  const [loading, setLoading] = useState(true)
  const [loadingError, setLoadingError] = useState(false)

  const resolveData = () =>  Promise.all([MyService.getInvoiceList(), MyService.getOrderList(), MyService.getAddressbook()]);

  const getData = async () => {
    setLoading(true);
    const [invoices, orders, addresses] = await resolveData();
    if(invoices.invoices && orders.orders && addresses.addresses) {
      setDashboardData({invoices: invoices.invoices.length, orders: orders.orders.length, addresses: addresses.addresses.length});
    } else{
      setLoadingError("fout bij het ophalen van de gegevens")
    }
    setLoading(false);
  }

  useEffect(() => {
    getData();
  }, [])
  return (
    <div>
      <Helmet>
        <title>
          Mijn Verzenden.nl - één verzendplatform voor grote en kleine pakketten
        </title>
        <link rel="canonical" href="https://www.verzenden.nl/my" />
        <meta
          name="description"
          content="Wat wil je vandaag verzenden? Kies uit onze betrouwbare verzendpartners!"
        />
      </Helmet>

      <Container className="container-content container-my mt-5">
        <MyMenuHeader />
        <Row className="d-flex align-items-stretch">
          <Col xs={12} lg={7}>
            <h1 className="d-inline mr-3">
              Dashboard
            </h1>
          </Col>
        </Row>

        {loadingError ? 
          <div className="alert alert-warning w-100 text-center">
            {loadingError}. <a href="#f" onClick={getData}>probeer opnieuw</a>
          </div> : 
        
          <Row className="mt-4">
            <Col xs={6} md={4} className="my-dashboard__highlight my-dashboard__highlight--yellow">

              <NavLink to="/mijn/facturen">
                {loading ?
                  <Loader noPadding color="white" />
                  : 
                  <span className="my-dashboard__highlight__count">{dashboardData.invoices}</span>
                }
                <span className="my-dashboard__highlight__title">facturen</span>
              </NavLink>
            </Col>

            <Col xs={6} md={4} className="my-dashboard__highlight my-dashboard__highlight--purple">
              <NavLink to="/mijn/orders">
                {loading ?
                  <Loader noPadding color="white" />
                  : 
                  <span className="my-dashboard__highlight__count">{dashboardData.orders}</span>
                }             
                <span className="my-dashboard__highlight__title">zendingen</span>
              </NavLink>
            </Col>

            <Col xs={6} md={4} className="my-dashboard__highlight my-dashboard__highlight--green">
              <NavLink to="/mijn/adresboek">
                {loading ?
                  <Loader noPadding color="white" />
                  : 
                  <span className="my-dashboard__highlight__count">{dashboardData.addresses}</span>
                }
                <span className="my-dashboard__highlight__title">adressen in adresboek</span>
              </NavLink>
            </Col>
          </Row>
        }
      </Container>
    </div>
  );
}

export default PageMyDashboard;
