import React, { useState } from "react";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { Helmet } from "react-helmet";

import {useHistory} from 'react-router-dom'

import classNames from 'classnames';
import axios from 'axios'

import FormInputText from "../../components/order/Form/FormInputText";
import MyService from '../../services/MyService'
import { Form, Field } from "react-final-form";

import { requiredValidator, emailValidator, composeValidators,postcodeValidator, houseNumberValidator } from "../../helpers/validators";

import "./PageRegister.scss";

// import iconLetter from "../assets/shipment-letter.svg";

function PageRegister() {
  const [addressEditable, setAddressEditable] = useState(false);
  const [addressLoading, setAddressLoading] = useState(false);
  const [registerStatus, setRegisterStatus] = useState();
  const [registerError, setRegisterError] = useState();
  const history = useHistory();


  const getAddressByPostcode = async (
    postcode,
    housenumber,
    housenumberAddition
  ) => {
    if (!postcode || !housenumber) {
      setAddressLoading(false);
      return false;
    }
    const startpath = document.location.pathname

    try {
      setAddressLoading(true);
      const response = await axios.post(
        `${process.env.CONF_API_BASE}/address/find`,
        { postcode, housenumber: `${housenumber}${housenumberAddition || ""}` }
      );
      if (response.status === 200 && startpath === document.location.pathname) {
        setAddressEditable(false);
        window.setFormValue("street", response.data.street);
        window.setFormValue("city", response.data.city);
      } else {
        setAddressEditable(true);
        window.setFormValue("street", null);
        window.setFormValue("city", null);
      }
      setAddressLoading(false);
    } catch (e) {
      console.error(e);
      setAddressEditable(true);
      window.setFormValue("street", null);
      window.setFormValue("city", null);
      setAddressLoading(false);
    }
  };
  
  const handleRegister = async (data) => {
    setRegisterStatus('loading')
    const register = await MyService.createAccount(data);
    if(register.error) {
      setRegisterError(register);
      setRegisterStatus("error");
      document.getElementsByClassName("alert")[0].scrollIntoView({behavior: "smooth", block: "end", inline: "nearest"});
    } else {
      setRegisterStatus()
      history.push(`/registreer/activeer/${register.id}`);
    }
  };

  return (
    <div>
      <Helmet>
        <title>
          Registreren - verzenden.nl: één verzendplatform voor grote en kleine pakketten
        </title>
        <link rel="canonical" href="https://www.verzenden.nl/" />
        <meta name="description" content="Login op uw Verzenden.nl accounnt" />
      </Helmet>

      <Container className="mt-5 pt-5 container-content">
        <Row>

          <Col xs={12} sm={12}>
            <h1 className="text-green text-uppercase">Registreren</h1>
            <div
              className="alert alert-info mt-3 alert-auto mb-3 w-100"
              role="alert"
            >
             Op dit moment is het alleen mogelijk om je aan te melden als <strong>zakelijke klant</strong>.
            </div>

            {registerError  && <div
              className="alert alert-warning mt-3 alert-auto mb-3 w-100"
              role="alert"
            >
              <strong>Fout bij registratie:</strong>
              &nbsp;
              {registerError.message}
            </div>}

            <Form
              onSubmit={handleRegister}
              mutators={{
              // expect (field, value) args from the mutator
                setValue: ([field, value], state, { changeValue }) => {
                  changeValue(state, field, () => value);
                },
              }}
              render={({
                handleSubmit,
                form,
                submitting,
                pristine,
                values,
                valid,
              }) => {
                window.setFormValue = form.mutators.setValue;
                return (
                  <form onSubmit={handleSubmit} className="mt-3">
                    <Row className="mt-5 mb-2 pl-1">
                      <Col>
                        <h2>Contactgegevens</h2>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={3} >
                        <div className="form-group">
                          <label>voornaam</label>
                          <Field name="firstName"  validate={requiredValidator}>
                            {(props) => <FormInputText  {...props} />}
                          </Field>
                        </div>
                      </Col>
                      <Col xs={5} >
                        <div className="form-group">
                          <label>achternaam</label>
                          <Field name="lastName" validate={requiredValidator}>
                            {(props) => <FormInputText {...props} />}
                          </Field>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12} md={8} >
                        <div className="form-group">
                          <label>bedrijfsnaam</label>
                          <Field name="companyname" validate={requiredValidator}>
                            {(props) => <FormInputText {...props} />}
                          </Field>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12} sm={4}>
                        <div className="form-group">
                          <label>telefoon</label>
                          {/* <small className="order__subtitle">
                          gebruiken voor Track & Trace
                        </small> */}
                          <Field
                            name="phoneNumber"
                            type="tel"
                            validate={
                              values.choosesDate
                                ? composeValidators(
                                  requiredValidator, //eslint-disable-line
                                  phoneValidator //eslint-disable-line
                                ) //eslint-disable-line
                                : undefined
                            }
                          >
                            {(props) => <FormInputText {...props} />}
                          </Field>
                        </div>
                      </Col>
                    </Row>

                    <Row className="mt-5 mb-2 pl-1">
                      <Col>
                        <h2>Adres</h2>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={6} sm={3}>
                        <div className="form-group">
                          <label>postcode*</label>
                          <Field
                            name="postcode"
                            validate={composeValidators(
                              requiredValidator,
                              postcodeValidator
                            )}
                          >
                            {(props) => (
                              <FormInputText
                                {...props}
                                onBlur={(e) => {
                                  getAddressByPostcode(
                                    values.postcode,
                                    values.housenumber,
                                    values.housenumberAddition
                                  );
                                }}
                              />
                            )}
                          </Field>
                        </div>
                      </Col>
                      <Col xs={6} sm={2}>
                        <div className="form-group">
                          <label>huisnummer*</label>
                          <Field
                            name="housenumber"
                            validate={composeValidators(
                              requiredValidator,
                              houseNumberValidator
                            )}
                            type="number"
                          >
                            {(props) => (
                              <FormInputText
                                onBlur={(e) => {
                                  getAddressByPostcode(
                                    values.postcode,
                                    values.housenumber,
                                    values.housenumberAddition
                                  );
                                }}
                                {...props}
                              />
                            )}
                          </Field>
                        </div>
                      </Col>
                      <Col xs={7} sm={2}>
                        <div className="form-group">
                          <label>toevoeging</label>
                          <Field name="housenumberAddition">
                            {(props) => (
                              <FormInputText
                                onBlur={(e) => {
                                  getAddressByPostcode(
                                    values.postcode,
                                    values.housenumber,
                                    values.housenumberAddition
                                  );
                                }}
                                {...props}
                              />
                            )}
                          </Field>
                        </div>
                      </Col>
                    </Row>

                    <Row>
                      <Col xs={12} sm={4}>
                        <div className="form-group">
                          <label>straat</label>
                          <Field name="street" validate={requiredValidator}>
                            {(props) => (
                              <FormInputText
                                {...props}
                                readonly={!addressEditable}
                                loading={addressLoading}
                              />
                            )}
                          </Field>
                        </div>
                      </Col>
                      <Col xs={12} sm={4}>
                        <div className="form-group">
                          <label>plaats</label>
                          <Field name="city" validate={requiredValidator}>
                            {(props) => (
                              <FormInputText
                                {...props}
                                readonly={!addressEditable}
                                loading={addressLoading}
                              />
                            )}
                          </Field>
                        </div>
                      </Col>
                    </Row>

                    <Row className="mt-5 mb-2 pl-1">
                      <Col>
                        <h2>Inloggegevens</h2>
                      </Col>
                    </Row>

                    <Row>
                      <Col xs={12} sm={8}>
                        <div className="form-group">
                          <label>e-mailadres</label>
                          <Field
                            name="email"
                            type="email"
                            validate={
                              composeValidators(
                                  requiredValidator, //eslint-disable-line
                                  emailValidator //eslint-disable-line
                                ) //eslint-disable-line
                            }
                          >
                            {(props) => <FormInputText {...props} />}
                          </Field>
                        </div>
                      </Col>
                    </Row>

                    <Row>
                      <Col xs={12} sm={4}>
                        <div className="form-group">
                          <label>Wachtwoord</label>
                          <Field
                            name="password"
                            type="password"
                            validate={
                              composeValidators(
                                  requiredValidator, //eslint-disable-line
                                   //eslint-disable-line
                                ) //eslint-disable-line
                            }
                          >
                            {(props) => <FormInputText {...props} />}
                          </Field>
                        </div>
                      </Col>
                      <Col xs={12} sm={4}>

                        <div className="form-group">
                          <label>Wachtwoord herhalen</label>
                          <Field
                            name="password2"
                            type="password"
                            validate={
                              composeValidators(
                                  requiredValidator, //eslint-disable-line
                                   //eslint-disable-line
                                ) //eslint-disable-line
                            }
                          >
                            {(props) => <FormInputText {...props} />}
                          </Field>
                        </div>
                      </Col>
                    </Row>
                

                    <Row>
                      <Col className="text-right">
                        <button
                          className={classNames("btn btn-primary", {
                            "btn-loading": registerStatus === 'loading' ,
                          })}
                          // disabled={!valid}
                        >
                        registreren
                        </button>
                      </Col>
                    </Row>

                  </form>
                )}} />
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default PageRegister;
