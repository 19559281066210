import * as Sentry from "@sentry/react";

const axios = require("axios");
const jwt = require("jsonwebtoken");
const errorCodes = require("./ErrorCodes.json");




export default class ApiService {


  getDecodedToken () {
    return jwt.verify(localStorage.getItem('authToken'),process.env.CONF_TOKEN_SECRET, (error, decoded) => {
      if(error) {
        if(error.name === 'TokenExpiredError' || error.message === "jwt must be provided") {
          window.location = '/login'
          return false
        } else {

          Sentry.captureException(new Error("Unaught token error"), {
            user: {
              token: localStorage.getItem('authToken'),
              error,
              decodedToken: decoded ? decoded : false
            }
          });
          window.location = '/login';
          return false;
        }

      } else {
        return decoded
      }
    })
  }

  getAccessToken () { return this.getDecodedToken().access_token}

  getApiErrorResponse (apiResponse) {
    if(typeof apiResponse === "object" && apiResponse.data) {
      if(apiResponse.data.error_code) {
        // map error
        const message = errorCodes[apiResponse.data.error_code] || false;

        if(!message) {
          return {error: true, message: apiResponse.data.message || apiResponse.data.error_code}
        } 

        return {error: true, message, errorCode: apiResponse.data.error_code}

      } else {
        Sentry.captureException(new Error(`API ERROR: ${JSON.stringify(apiResponse.data)}`));
        return {error: true, message: "Onbekende fout opgetreden"}
      }
    
    } else if(typeof apiResponse === "object" && apiResponse.status) {
      const statusCodes = {400: "Onjuiste aanvraag", 401: "Niet geauthoriseerd", 403: "Geen toegang", 404: "Niet gevonden", 405:"Niet toegestaan"}
      const message = statusCodes[apiResponse.status] || `Onbekende fout opgetreden: ${apiResponse.status}`;
      return {error: true, message}
    }

  }

  getApiErrorExceptionResponse (exception) {
    Sentry.captureException(new Error(exception));
    return {error: true, message: typeof exception === "object" ? JSON.stringify(exception) : String(exception)};
  }




  get authorizationHeaders() {
    return {headers: {'Authorization': `Bearer ${this.getAccessToken()}`}}
  }

  async get(endpoint, authenticated = true) { 
    try {
      const request = await axios.get(endpoint, authenticated ? this.authorizationHeaders : {});
      if (request.status >= 200 || request.status < 300 ) {
        return request.data;
      } else {
        return this.getApiErrorResponse(request);
      }
    } catch(e) {
      if(e.response) {
        return this.getApiErrorResponse(e.response);
      }
      return this.getApiErrorResponse(e);
    }
  }

  async post(endpoint, data = null, authenticated = true) { 
    try {
      const request = await axios.post(endpoint, data ? data : null , authenticated ? this.authorizationHeaders : null);
      if (request.status >= 200 || request.status < 300 ) {
        return request.data;
      } else {
        return this.getApiErrorResponse(request);
      }
    } catch(e) {
      if(e.response) {
        return this.getApiErrorResponse(e.response);
      }
      return this.getApiErrorResponse(e);
    }
  }

  async delete(endpoint, authenticated = true) { 
    try {
      const request = await axios.delete(endpoint, authenticated ? this.authorizationHeaders : null);
      if (request.status >= 200 || request.status < 300 ) {
        return request.data;
      } else {
        return this.getApiErrorResponse(request);
      }
    } catch(e) {
      if(e.response) {
        return this.getApiErrorResponse(e.response);
      }
      return this.getApiErrorResponse(e);
    }
  }
}