import React from "react";
import classNames from "classnames";

import "./Loader.scss";

function Loader({color, noPadding, right70}) {
  return (
    <div className="text-center w-100">
      <span className={classNames("loader", {"loader--white": color === "white", "loader--nopadding": noPadding, "loader--right-70": right70})}></span>
    </div>
  );
}

export default Loader;
