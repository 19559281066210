import React, { useState, useEffect } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { Helmet } from "react-helmet";
import { Form, Field, useField } from "react-final-form";
import Loader from "../../components/Loader/Loader";

import classNames from "classnames";

import "./OrderFlow.scss";
import {
  max32kgValidator,
  isWeightValidator,
  composeValidators,
  colliValidator,
} from "../../helpers/validators";
import Col from "react-bootstrap/Col";
import { Link, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
const axios = require("axios");

const Error = ({ name }) => {
  const {
    meta: { touched, error },
  } = useField(name, { subscription: { touched: true, error: true } });
  return touched && error ? (
    <div
      className="alert alert-warning alert-auto mt-2 "
      role="alert"
      dangerouslySetInnerHTML={{ __html: error }}
    ></div>
  ) : null;
};

function PageOrderParcel() {
  const history = useHistory();
  const [orderOffer, setOrderOffer] = useState();
  const [selectedOrderOffer, setSelectedOrderOffer] = useState();
  const [orderOfferLoading, setOrderOfferLoading] = useState()
  const initialOffer = useSelector((state) => ({
    id: state.order.parcel ? state.order.parcel.offerId : null,
    weight: state.order.parcel ? state.order.parcel.weight : null,
    colli: state.order.parcel ? state.order.parcel.colli : null,
    lastUpdatedAt: state.order.lastUpdatedAt || null,
  }));

  const dispatch = useDispatch();
  const onGetOffer = async (form) => {
    dispatch({
      type: "SET_WEIGHT",
      payload: { weight: form.weight, colli: form.colli },
    });
    await setOrderOfferLoading(true);
    const offer = await getOffer(form.weight, form.colli);
    await setOrderOffer(offer || false);
    await setOrderOfferLoading(false);

  };

  const getOffer = async (weight, colli) => {
    // debugger;
    if(weight === initialOffer.weight && colli === initialOffer.colli && orderOffer) return orderOffer;
    try {
      const req = await axios.get(`${process.env.CONF_API_BASE}/rate?weight=${weight}&colli=${colli}`);
      const offer = req.data;
      dispatch({
        type: "SET_OFFER",
        payload: { rate: offer.rate },
      });
      return offer;
    } catch (e) {
      console.error(e);
      return false;
    }
  };

  useEffect(() => {
    const runAsync = async () => {
      // debugger;
      if (initialOffer && initialOffer.weight && !orderOffer && !orderOfferLoading) {
        await onGetOffer({weight: initialOffer.weight, colli: initialOffer.colli });
      } else if (initialOffer && initialOffer.id && !selectedOrderOffer) {
        const offer = await getOffer(initialOffer.weight, initialOffer.colli);
        await setOrderOffer(offer || false);
        setSelectedOrderOffer(initialOffer.id);
      }
    };
    runAsync();
  }, []);

  const clearOldSession = () => {
    if(initialOffer.lastUpdatedAt) {
      const sessionDate = new Date(initialOffer.lastUpdatedAt);
      const resetDate = new Date();
      resetDate.setTime(resetDate.getTime() - (1*60*60*1000));
      if(sessionDate.getTime() < resetDate.getTime()) {
        dispatch({type: 'CLEAR_ORDER'});
      }
    }
  }

  useEffect(clearOldSession, [initialOffer]);

  return (
    <div>
      <Helmet>
        <title>
          Pakket verzenden - verzenden.nl: één verzendplatform voor grote en
          kleine pakketten
        </title>
        <link rel="canonical" href="https://www.verzenden.nl/zending/pakket" />
      </Helmet>

      <Container className="mt-3 pt-5">
        <div className="container-content">
          
          <h1>Wat wil je verzenden?</h1>
          <span className="order__subtitle">
            Op basis van het aantal pakketten en het gewicht kunnen we je het beste aanbod doen.
          </span>
          <small className="d-block text-gray mt-2">
           Maximale afmeting per item: <strong>70x58x100cm</strong>. Afwijkende afmetingen? <Link to="/contact" tabIndex={-1}>Neem contact op</Link>.
          </small>

          <Row>

            <Col xs={12}>
    
              <div className="boxed-grey mt-4">
                <div className="d-block d-md-flex">
                  <div className="d-lex">
                    <Form
                      onSubmit={onGetOffer}
                      initialValues={{ weight: initialOffer.weight, colli: initialOffer.colli }}
                      render={({ handleSubmit, form, submitting, pristine, values, valid, sub }) => (
                        <form onSubmit={handleSubmit}>
                          <Row className="">
                            <div className="col-auto pr-0 pb-0 ">
                              <div className="form-group mb-0">
                                <label>Gewicht:*</label>
                                <div className="input-group" style={{ width: 180 }}>
                                  <Field
                                    name="weight"
                                    type="text"
                                    validate={composeValidators(
                                      max32kgValidator,
                                      isWeightValidator
                                    )}
                                  >
                                    {({ input, meta }) => (
                                      <>
                                        <input
                                          type="text"
                                          {...input}
                                          className={classNames("form-control", {
                                            "is-invalid": meta.error && meta.touched,
                                            "is-valid": !meta.error && meta.touched,
                                          })}
                                          onBlur={(e) => {
                                            input.onChange(e);
                                            setOrderOffer(false);
                                            handleSubmit()
                                          }}
                                        />
                                      </>
                                    )}
                                  </Field>
                                  <div className="input-group-append">
                                    <div className="input-group-text">kg</div>
                                  </div>
                                </div>
                                <Error name="weight" />
                              </div>
                            </div>
                            <div className="col-auto pr-0 pb-0 ">
                              <div className="form-group mb-0">
                                <label>Aantal pakketten:</label>
                                <div className="input-group" style={{ width: 180 }}>
                                  <Field
                                    name="colli"
                                    defaultValue={1}
                                    type="text"
                                    validate={colliValidator}
                                  >
                                    {({ input, meta }) => (
                                      <>
                                        <input
                                          type="text"
                                          {...input}
                                          className={classNames("form-control", {
                                            "is-invalid": meta.error && meta.touched,
                                            "is-valid": !meta.error && meta.touched,
                                          })}
                                          onChange={(e) => {
                                            input.onChange(e);
                                            setOrderOffer(false);
                                            handleSubmit()
                                          }}
                                        />
                                      </>
                                    )}
                                  </Field>
                                  <div className="input-group-append">
                                    <div className="input-group-text">stuk(s)</div>
                                  </div>
                                  <Error name="colli" />
                                </div>
                              </div>
                            </div>
                        
                          </Row>

                        </form>
                      )}
                    />
                    <small className="mt-3 d-block text-gray ml-2">
                      * gewicht van het zwaarste pakket.
                    </small>
                  </div>
                                        
                  {orderOffer && (
                    <div className="flex-grow-1 text-center text-md-right mt-5 mt-md-4 pt-1">
                      <h1 className="d-inline-block  ml-5 mr-5 align-middle">{orderOffer.rate.toLocaleString('nl-NL', {
                        style: 'currency', 
                        currency: 'EUR', 
                        minimumFractionDigits: 2 
                      })}</h1>
                      <button
                        className={classNames("d-inline-block btn btn-primary w-00 align-self-baseline", {
                          "btn-loading": false,
                        })}
                        onClick={() => {
                          history.push("/verzenden/adres");
                        }
                        }
                      >
                  verder naar adres
                      </button>
                    </div>
                  )}
                  {!orderOffer && initialOffer.weight && initialOffer.colli && initialOffer.colli > 0 && (
                    <div className="flex-grow-1 text-right mt-4 pt-1">
                      <Loader noPadding={true} right70/>
                    </div>
                  ) }
                </div>

              </div>
            </Col>

          </Row>
        </div>

        <div className="text-center mt-4">
          
          <small className="text-light-gray"><strong>waarom verzenden.nl: </strong>zending wordt <strong>gratis opgehaald</strong> &#8226; zelf <strong>bezorgmoment kiezen</strong> 	&#8226; geschikt voor <strong>kwetsbare pakketten</strong>	<br /> <strong>Track &amp; Trace</strong> voor jou en ontvanger 	&#8226; ook voor <strong>zendingen naar België</strong> (+ &euro; 25,00 toeslag)</small>


        </div>
      </Container>
    </div>
  );
}

export default PageOrderParcel;
