import React from "react";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { Helmet } from "react-helmet";
import { Form, Field } from "react-final-form";
import Cleave from "cleave.js/react";

import "./PageHome.scss";

// import iconLetter from "../assets/shipment-letter.svg";
import iconPackage from "../assets/shipment-package.svg";
import iconSofa from "../assets/shipment-sofa.svg";
import { NavLink, useHistory } from "react-router-dom";

const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

function PageHome() {
  const onSubmit = async (val) => {
    await sleep(200);
    history.push(`/trace/${val.barcode}/${val.postcode}`);
  };

  const history = useHistory();

  return (
    <div>
      <Helmet>
        <title>
          verzenden.nl: één verzendplatform voor grote en kleine pakketten
        </title>
        <link rel="canonical" href="https://www.verzenden.nl/" />
        <meta
          name="description"
          content="Wat wil je vandaag verzenden? Kies uit onze betrouwbare verzendpartners!"
        />
      </Helmet>

      <Container className="container-content container-home mt-5 pt-5">
        <Row className="d-flex align-items-stretch">
          <Col xs={12} lg={7}>
            <h1 className="text-green text-uppercase h1-home">
              Één verzendplatform voor grote en kleine pakketten.
            </h1>
            <h2 className="text-purple mt-3 h2-home">Zakelijk en particulier pakketten verzenden</h2>

            <Col xs={12} lg={7} className="p-0">
              <span className="pagehome__shipment__label">Wat wilt u verzenden?</span><br />

              <div className="d-flex justify-content-between">
                <NavLink to="/verzenden" className="pagehome__shipment">
                  <img src={iconPackage} alt="" />
                  <span>pakket</span>
                </NavLink>
                <NavLink to="/verzenden" className="pagehome__shipment">
                  <img src={iconSofa} alt="" />
                  <span>XL-zending </span>
                </NavLink>
              </div>
            </Col>

          </Col>
          <Col xs={12} lg={5} className="pagehome__partners align-self-stretch">
            <div className="text-center w-100 pagehome__partners__content">
            </div>
          </Col>

   
        </Row>
      </Container>
      <Container className="  text-center">


        <Row>


          <Col xs={12} sm={{ offset: 2, span: 8 }} className="mt-5 pt-5 mt-lg-0">
            <Form
              onSubmit={onSubmit}
              render={({
                handleSubmit,
                form,
                submitting,
                pristine,
                values,
              }) => (
                <form onSubmit={handleSubmit}>
                  <div className="pagehome__sendbox mt-5">
                    <span>Track & trace</span>
                    <br />
                    <br />
                    <Row className="mt-3">
                      <Col xs={12}>
                        <Field
                          name="barcode"
                          component="input"
                          type="text"
                          placeholder="PAKKETNUMMER"
                        />
                      </Col>
                    </Row>
                    <div className="d-lg-flex mt-3">
                      <div className="flex-grow-1 ">
                        {/* <Field
                          name="postcode"
                          component="input"
                          type="text"
                          placeholder="POSTCODE"
                          className="postcode"
                        /> */}
                        <Field name="postcode">
                          {(props) => (
                            <Cleave
                              placeholder="POSTCODE"
                              options={{ blocks: [6], uppercase: true }}
                              {...props.input}
                            />
                          )}
                        </Field>
                      </div>
                      <div>
                        <button type="submit" className="w-100 w-lg-auto ml-0 ml-lg-3 mt-3 mt-lg-0" disabled={submitting}>
                          volg pakket
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              )}
            />
          </Col>
        </Row>

        <Row>
          {/* <Col xs={12} sm={{span: 10, offset: 1}} className="mt-5 text-left">
            <div className="container-content mt-5">
              <h1 className="" >Contactformulier</h1>

              <FaqContactForm />
            </div>
          </Col>*/}
        </Row> 
      </Container>
    </div>
  );
}

export default PageHome;
