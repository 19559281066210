import React, { useState, useEffect } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { Helmet } from "react-helmet";
import { Form, Field } from "react-final-form";
import classNames from "classnames";

import "./OrderFlow.scss";
import {
  composeValidators,
  requiredValidator,
  postcodeValidator,
  houseNumberValidator,
  emailValidator,
} from "../../helpers/validators";
import Col from "react-bootstrap/Col";
import FormInputText from "../../components/order/Form/FormInputText";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, NavLink } from "react-router-dom";

import moment from "moment";
import  "moment/locale/nl";
import Axios from "axios";

const axios = require("axios");

function PageOrderPickupAddress() {
  moment.locale('nl');
  
  const [addressEditable, setAddressEditable] = useState(false);
  const [addressLoading, setAddressLoading] = useState(false);
  const [availablePickupDates, setAvailablePickupDates] = useState([]);

  const dispatch = useDispatch();
  const history = useHistory();
  const initialAddress = useSelector((state) => state.order.pickupAddress);

  const onSubmit = async (form) => {
    await dispatch({
      type: "SET_PICKUP_ADDRESS",
      payload: { address: form },
    });
    history.push("/verzenden/factuuradres");
  };

  const hasDeliveryAddress = useSelector(
    (state) =>
      state.order.deliveryAddress && !!state.order.deliveryAddress.postcode
  );

  const preflightCheck = () => {
    if (!hasDeliveryAddress) {
      history.push("/verzenden/adres");
    }
  };

  useEffect(preflightCheck, []);



  const fetchAvailablePickupDates = async (postcode) => {
    const response = await Axios.get(`${process.env.CONF_API_BASE}/pickup/options?postcode=${postcode}`);

    if(response.status === 200) {
      setAvailablePickupDates(response.data);

      if(!initialAddress || !initialAddress.pickupDate) {
        window.setFormValue('pickupDate', response.data[0])
      }
    } else {  
      setAvailablePickupDates([]);
    }
  }

  useEffect(() => {
    if(initialAddress && initialAddress.postcode) {
      fetchAvailablePickupDates(initialAddress.postcode);
    }
  }, [initialAddress]);

  const getAddressByPostcode = async (
    postcode,
    housenumber,
    housenumberAddition
  ) => {

    if(postcode) {
      fetchAvailablePickupDates(postcode);
    }
    
    if (!postcode || !housenumber) {
      setAddressLoading(false);
      return false;
    }

    const startpath = document.location.pathname;

    try {
      setAddressLoading(true);
      const response = await axios.post(
        `${process.env.CONF_API_BASE}/address/find`,
        { postcode, housenumber: `${housenumber}${housenumberAddition || ""}` }
      );
      if (response.status === 200 && startpath === document.location.pathname) {
        setAddressEditable(false);
        window.setFormValue("street", response.data.street);
        window.setFormValue("city", response.data.city);
      } else {
        setAddressEditable(true);
        window.setFormValue("street", null);
        window.setFormValue("city", null);
      }
      setAddressLoading(false);
    } catch (e) {
      console.error(e);
      setAddressEditable(true);
      window.setFormValue("street", null);
      window.setFormValue("city", null);
      setAddressLoading(false);
    }
  };

  return (
    <div>
      <Helmet>
        <title>
          Pakket verzenden - verzenden.nl: één verzendplatform voor grote en
          kleine pakketten
        </title>
        <link
          rel="canonical"
          href="https://www.verzenden.nl/zending/ophaaladres"
        />
      </Helmet>

      <Container className="mt-3 pt-5">
        <div className="container-content">
          <h1>Ophaaladres</h1>
          <span className="order__subtitle">
            Pakketten via Swift worden kostenloos opgehaald op een locatie naar
            keuze.
          </span>
          <Form
            onSubmit={onSubmit}
            initialValues={initialAddress}
            mutators={{
              setValue: ([field, value], state, { changeValue }) => {
                changeValue(state, field, () => value);
              },
            }}
            render={({
              handleSubmit,
              form,
              submitting,
              pristine,
              values,
              valid,
            }) => {
              window.setFormValue = form.mutators.setValue;
              return (
                <form onSubmit={handleSubmit} className="mt-3">
                  <Row>
                    <Col xs={12} sm={5}>
                      <div className="form-group">
                        <label>Voornaam</label>
                        <Field name="firstName" validate={requiredValidator}>
                          {(props) => <FormInputText {...props} />}
                        </Field>
                      </div>
                    </Col>
                    <Col xs={12} sm={7}>
                      <div className="form-group">
                        <label>Achternaam</label>
                        <Field name="lastName" validate={requiredValidator}>
                          {(props) => <FormInputText {...props} />}
                        </Field>
                      </div>
                    </Col>

                    <Col xs={12} sm={12}>
                      <div className="form-group">
                        <label>Bedrijfsnaam</label>
                        <Field name="companyName">
                          {(props) => <FormInputText {...props} />}
                        </Field>
                      </div>
                    </Col>
                  </Row>

                  <Row>
                    <Col xs={12} sm={4}>
                      <div className="form-group">
                        <label>Telefoon</label>
                        <Field name="phoneNumber" type="tel">
                          {(props) => <FormInputText {...props} />}
                        </Field>
                      </div>
                    </Col>
                    <Col xs={12} sm={8}>
                      <div className="form-group">
                        <label>E-Mailadres</label>
                        <Field
                          name="email"
                          type="email"
                          validate={composeValidators(
                            requiredValidator,
                            emailValidator
                          )}
                        >
                          {(props) => <FormInputText {...props} />}
                        </Field>
                      </div>
                    </Col>
                  </Row>

                  <Row className="mt-5 mb-2 pl-1">
                    <Col>
                      <h2>Adres</h2>
                    </Col>
                  </Row>

                  <Row>
                    <Col xs={6} sm={3}>
                      <div className="form-group">
                        <label>postcode*</label>
                        <Field
                          name="postcode"
                          validate={composeValidators(
                            requiredValidator,
                            postcodeValidator
                          )}
                        >
                          {(props) => (
                            <FormInputText
                              {...props}
                              onBlur={(e) => {
                                getAddressByPostcode(
                                  values.postcode,
                                  values.housenumber,
                                  values.housenumberAddition
                                );
                              }}
                            />
                          )}
                        </Field>
                      </div>
                    </Col>
                    <Col xs={6} sm={2}>
                      <div className="form-group">
                        <label>huisnummer*</label>
                        <Field
                          name="housenumber"
                          type="number"
                          validate={composeValidators(
                            requiredValidator,
                            houseNumberValidator
                          )}
                        >
                          {(props) => (
                            <FormInputText
                              onBlur={(e) => {
                                getAddressByPostcode(
                                  values.postcode,
                                  values.housenumber,
                                  values.housenumberAddition
                                );
                              }}
                              {...props}
                            />
                          )}
                        </Field>
                      </div>
                    </Col>
                    <Col xs={7} sm={2}>
                      <div className="form-group">
                        <label>toevoeging</label>
                        <Field name="housenumberAddition">
                          {(props) => (
                            <FormInputText
                              onBlur={(e) => {
                                getAddressByPostcode(
                                  values.postcode,
                                  values.housenumber,
                                  values.housenumberAddition
                                );
                              }}
                              {...props}
                            />
                          )}
                        </Field>
                      </div>
                    </Col>
                  </Row>

                  <Row>
                    <Col xs={12} sm={5}>
                      <div className="form-group">
                        <label>Straat</label>
                        <Field name="street" validate={requiredValidator}>
                          {(props) => (
                            <FormInputText
                              {...props}
                              readonly={!addressEditable}
                              loading={addressLoading}
                            />
                          )}
                        </Field>
                      </div>
                    </Col>
                    <Col xs={12} sm={5}>
                      <div className="form-group">
                        <label>Plaats</label>
                        <Field name="city" validate={requiredValidator}>
                          {(props) => (
                            <FormInputText
                              {...props}
                              readonly={!addressEditable}
                              loading={addressLoading}
                            />
                          )}
                        </Field>
                      </div>
                    </Col>
                  </Row>

                  <Row className="mt-5 mb-2 pl-1">
                    <Col>
                      <h2>Ophaalmoment</h2>
                    </Col>
                  </Row>

                  <Row>
                    <Col xs={6} sm={4}>
                      <div className="form-group">
                        <label>Datum*</label>
                        <Field
                          name="pickupDate"
                          type="select"
                          validate={requiredValidator}
                        >
                          {({input, meta}) => (
                            <select 
                              name={input.name}
                              onChange={input.onChange}
                              value={input.value}
                              onBlur={input.onBlur}
                              onFocus={input.onFocus}
                              className={classNames(`form-control`, {
                                "is-invalid": meta.error && meta.touched,
                                "is-valid": !meta.error && meta.touched && meta.dirty ,
                              })}
                            >
                              {!availablePickupDates.length && <option disabled>geen opties voor deze postcode</option>}
                              {availablePickupDates.map((date, i) => (
                                <option key={date} value={date}>{moment(date).format('dddd -  LL')}</option>
                              ))}
                            </select>
                          )}
                        </Field>
                      </div>
                    </Col>
                    
                    {/*<Col xs={6} sm={{offset:1, span:4}}>
                      <div className="form-group form-radio">
                        <label>Tijdvak*</label>
                        <Field
                          name="pickupDayPart"
                          validate={requiredValidator}
                          type="radio"
                          value="ochtend"

                        >
                          {({input}) => (
                            <>
                              <div className="form-check">
                                <input  className="form-check-input" 
                                  type="radio" 
                                  id="pickupWindow1" 
                                  value="ochtend" 
                                  checked={input.checked}
                                  onChange={input.onChange} />
                                <label className="form-check-label" htmlFor="pickupWindow1">
                                09:00 - 14:00
                                </label>
                              </div>
                            </>
                          )}
                        </Field>
                         <Field
                          name="pickupDayPart"
                          validate={requiredValidator}
                          type="radio"
                          value="middag"
                        >
                          {({input, meta}) => (
                            <>
                              <div className="form-check">
                                <input className="form-check-input" 
                                  type="radio" 
                                  id="pickupWindow2" 
                                  value="middag" 
                                  checked={input.checked}
                                  onChange={input.onChange}/>
                                <label className="form-check-label" htmlFor="pickupWindow2">
                                13:00 - 18:00
                                </label>
                              </div>
                              {meta.touched && meta.error && (
                                <span
                                  className="form-error"
                                  role="alert"
                                  dangerouslySetInnerHTML={{ __html: meta.error }}
                                ></span>
                              )}
                            </>
                          )}
                        </Field> 
                      </div>
                    </Col>*/}
                  </Row>

                  <Row>
                    <Col className="text-right">
                      <NavLink
                        className="btn btn-outline-primary mr-sm-3 mb-2 mb-sm-0"
                        to="/verzenden/adres"
                      >
                          terug
                      </NavLink>
                      <button
                        className={classNames("btn btn-primary", {
                          "btn-loading": false,
                        })}
                        disabled={!valid}
                      >
                        verder
                      </button>
                    </Col>
                  </Row>
                </form>
              );
            }}
          />
        </div>
      </Container>
    </div>
  );
}

export default PageOrderPickupAddress;
