import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { Helmet } from "react-helmet";


import "../My.scss";

import MyService from "../../../services/MyService";
import MyMenuHeader from "../../../components/My/MyMenuHeader";


import { Link, useParams } from "react-router-dom";
import Loader from "../../../components/Loader/Loader";


import moment from 'moment';
import 'moment/locale/nl';


function PageMyOrdersView() {

  const [orderData, setOrderData] = useState({loading: true});
  const [orderError, setOrderError] = useState();

  const params = useParams();

  const getOrder = async () => {
    setOrderData({loading: true});

    const order = await MyService.getOrder(params.guid);
    if(order.error) {
      setOrderError(order);
      setOrderData({})
    } else {
      setOrderData(order);
    }
  }

  useEffect(() => {
    getOrder()
  },[])

  const AddressBlock = ({ address }) => (
    address ? 
      <>
    
        {`${address.firstName} ${address.lastName}`}

        {address.companyName && (
          <>
            <br />
            {address.companyName}
          </>
        )}
        <br />
        <br />
        {`${address.street} ${address.housenumber}${
          address.housenumberAddition || ""
        }`}
        <br />
        {`${address.postcode} ${address.city}`}
        {address.country === 'BE' && (<><br />België</>)}
        {address.note && (
          <>
            <br />
            <span className="order__subtitle">{address.note}</span>
          </>
        )}
        <br />
        <br />
        {address.phoneNumber}
        <br />
        {address.email}
      </> : <> </>
  );
  
  return (
    <div>
      <Helmet>
        <title>
          Bekijk zending - Mijn Verzenden - één verzendplatform voor grote en kleine pakketten
        </title>
        <link rel="canonical" href="https://www.verzenden.nl/mijn/adresboek" />
        <meta
          name="description"
          content="Wat wil je vandaag verzenden? Kies uit onze betrouwbare verzendpartners!"
        />
      </Helmet>

      <Container className="container-content container-my mt-5">
        <MyMenuHeader />
        <Row>
          <Col>
            <h1>Bekijk zending {orderData && orderData.number}</h1>
          </Col>
        </Row>
        <Row>

          <Col>
            {orderData.loading && <Loader />}
            {orderError &&               
          <div
            className="alert alert-warning alert-auto mt-5 text-center w-100"
            role="alert"
          >
            {orderError.errorCode === "NOT_FOUND" ? 
              `Deze zending kan niet worden gevonden!` 
              : 
              <>
                {orderError.message} 
                <a href="#0" onClick={getOrder}>probeer opnieuw</a>
              </>}
          </div>}
            
          </Col>
        </Row>
        <Row>
          {orderData && orderData.pickupAddress  && (
            <Col sm={4}>
              <Row className="mt-4 p-4">
                <Col xs={12}>
                  <h2 className="mb-2">
                      Afhaaladres
                  </h2>
                </Col>
                <Col xs={12} className="boxed-grey">
                  <AddressBlock address={orderData.pickupAddress} />
                </Col>
              </Row>
            </Col>
          )}

          {orderData && orderData.dropoffAddress  && (
            <Col sm={4}>
              <Row className="mt-4 p-4">
                <Col xs={12}>
                  <h2 className="mb-2">
                      Afleveradres
                  </h2>
                </Col>
                <Col xs={12} className="boxed-grey">
                  <AddressBlock address={orderData.dropoffAddress} />
                </Col>
              </Row>
            </Col>
          )}
          {orderData && orderData.number && (<Col sm={4}>
            <Row className="mt-4 p-4">
              <Col xs={12}>
                <h2 className="mb-2">
                      &nbsp;
                </h2>
              </Col>
              <Col xs={12} className="boxed-grey">
                <Link target="new" to={`/trace/${orderData.number}/${orderData.dropoffAddress.postcode}`}>
                  <button className="btn btn-primary w-100 mb-4">track &amp; trace</button>
                </Link>
                <a href={`${process.env.CONF_API_HOST}${orderData.labelUri}`} target="_new">
                  <button className="btn btn-secondary w-100 mb-4">download verzendlabel</button>
                </a>

              </Col>
            </Row>
          </Col>
          )}
        </Row>
        <Row>
          {orderData  && (
            <Col sm={4}>
              <Row className="mt-4 p-4">
                <Col xs={12}>
                  <h2 className="mb-2">
                      Afhaal tijdvak
                  </h2>
                </Col>
                <Col xs={12} className="boxed-grey">
                  <strong>Datum:</strong><br />
                  {moment(orderData.pickupDate).format('LLLL').replace('00:00', '')}

                  <br /><br />
                  <strong>Tijdvak:</strong><br />
                  {orderData.pickupTime}
                  
                </Col>
              </Row>
            </Col>
          )}

          {orderData  && (
            <Col sm={4}>
              <Row className="mt-4 p-4">
                <Col xs={12}>
                  <h2 className="mb-2">
                      Aflever tijdvak
                  </h2>
                </Col>
                <Col xs={12} className="boxed-grey">
                  <strong>Datum:</strong><br />
                  {moment(orderData.dropoffDate).format('LLLL').replace('00:00', '')}

                  <br /><br />
                  <strong>Tijdvak:</strong><br />
                  {orderData.dropoffTime}
                  
                </Col>
              </Row>
            </Col>
          )}

        </Row>
        {orderData.products && !!orderData.products.length && (
          <Row className="mt-3 p-4">
            <Col xs={12}>
              <h2 className="mb-4">
                  Producten
              </h2>
            </Col>            <Col xs={12}>
              <div className="my-table__wrapper">
                <table className="my-table">
                  <tr className="my-table__header">
                    <td>Product</td>
                    <td>Naam</td>
                    <td>Nummer</td>
                  </tr>

                  {orderData.products.map((product) => (
                    <tr>
                      <td>{product.productGroup}</td>
                      <td>{product.name}</td>
                      <td>{product.number}</td>
                    </tr>
                  ))}

                </table>
              </div>
            </Col>
          </Row>
        )}
      </Container>
    </div>
  );
}

export default PageMyOrdersView;
