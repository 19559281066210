import React from "react";


import "./TrackTraceStatus.scss";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
var { DateTime } = require('luxon');


const formatDate = (rawDate) => {
  if(!rawDate) return '';
  const date = DateTime.fromSQL(rawDate).setLocale('nl-NL');
  return `${date.toLocaleString()} (${date.toRelativeCalendar()}) - ${date.toFormat('HH:mm')}`;
};

const AddressBlock = ({address}) => (
  <div className="ttstatus__address">
    {address.companyName ? 
      <>
        <strong>{address.companyName}</strong>
        {`${address.firstName} ${address.lastName}`} <br />
      </>
      :
      <>
        <strong>{`${address.firstName} ${address.lastName}`}</strong>
      </>}
    {`${address.street} ${address.housenumber} ${address.housenumberAddition || ''}`}
    <br />
    {`${address.postcode} ${address.city}`}
  </div>
);

function TrackTraceStatus({status}) {
  const statusHistory = [status.currentStatus, ...status.previousStatusses];

  return (
    <div className="ttstatus">
      <Row>
        <Col xs={12} className=" mt-3 text-center">
          <h2 className="mb-3 mb-5 ">{status.orderNumber}</h2>
          <div xs={12} className=" w-100 ttstatus__currentstatus">
            {status.currentStatus.statusDescription}
          </div>
          <span className="ttstatus__currentstatus__date">
            {formatDate(status.currentStatus.updateDate)}
          </span>
        </Col>
      </Row>
      <Row className="mt-5 text-center border-top pt-3 mx-1">
        <Col xs={12} lg={6} className="boxed-grey border-sm-right" >
          <h3 className="mb-4">Historie</h3>
          {statusHistory && statusHistory.map(status => (
            <div className="ttstatus__history-item">
              <span className="ttstatus__history-item__date">
                {formatDate(status.updateDate)}
              </span>
              <span className="ttstatus__history-item__description">
                {status.statusDescription}
              </span>
            </div>
          ))}
        </Col>

        <Col xs={12} lg={6} className="text-left pl-lg-5 pt-4">
          <h3 className="mb-4">Afleveradres</h3>
          <AddressBlock address={status.dropoffAddress} />

          <h3 className="mb-4">Afzender</h3>
          <AddressBlock address={status.pickupAddress || status.invoiceAddress} />
        </Col>
      </Row>
    </div>
  );
}

export default TrackTraceStatus;
