import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { Helmet } from "react-helmet";

import "../My.scss";
import { NavLink } from "react-router-dom";

import MyService from "../../../services/MyService";
import MyMenuHeader from "../../../components/My/MyMenuHeader";

import moment from 'moment';
import 'moment/locale/nl';


function PageMyInvoices() {
  const [invoices, setInvoices] = useState([]);
  const [dataLoading, setDataLoading] = useState(true);
  const [error,setError] = useState()

  useEffect(() => {
    MyService.getInvoiceList().then((invoices) => {
      if(invoices.error){
        setError(error);
      } else {
        setInvoices(invoices); 
        setError(false)
      }
      setDataLoading(false);});
  }, [])

  return (
    <div>
      <Helmet>
        <title>
          Facturen - Mijn Verzenden - één verzendplatform voor grote en kleine pakketten
        </title>
        <link rel="canonical" href="https://www.verzenden.nl/mijn/facturen" />
        <meta
          name="description"
          content="Wat wil je vandaag verzenden? Kies uit onze betrouwbare verzendpartners!"
        />
      </Helmet>

      <Container className="container-content container-my mt-5">
        <MyMenuHeader />
        <Row>
          <Col>
            <h1 className="d-inline mr-3">Facturen</h1>
          </Col>
        </Row>

        {error && <div className="alert alert-warning w-100 text-center mt-4">
          {error.message}
        </div>}
        <Row className="mt-4">
          <Col>
            {/* {addresses && JSON.parse(addresses)} */}
            <div className="my-table__wrapper">
              <table className="my-table">
                <tr className="my-table__header">
                  <th width="25%">factuurnummer</th>
                  <th width="50%">datum</th>
                  <th width="25%">download factuur</th>
                </tr>
                {dataLoading && <tr>
                  <td><span className="my-table__skeleton"></span></td>
                  <td><span className="my-table__skeleton"></span></td>
                  <td><span className="my-table__skeleton"></span></td>
                </tr>}
                {invoices.invoices && invoices.invoices.map((invoice) =>
            
                  <tr>
                    <td><a href={`${process.env.CONF_API_HOST}${invoice.downloadAddress}`} target="_new" className="cta">{invoice.number}</a></td>
                    <td><NavLink to={`/mijn/facturen/${invoice.id}`}>{moment(invoice.date).format('L')}</NavLink></td>
                    <td><a href={`${process.env.CONF_API_HOST}${invoice.downloadAddress}`} target="_new" className="cta">download PDF</a></td>

                  </tr>)}
              </table>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default PageMyInvoices;
