const defaultState = {parcel: {offerProvider: 'Swift'}};

const order = (state = defaultState, action) => {
  switch (action.type) {
    
  case 'SET_WEIGHT':
    return {
      ...state,
      parcel: {
        ...state.parcel,
        weight: action.payload.weight,
        colli: action.payload.colli,
        offerProvider: 'Swift'
      },
    }

  case 'SET_OFFER':
    return {
      ...state,
      parcel: {
        ...state.parcel,
        rate: action.payload.rate,
      },
      lastUpdatedAt: new Date(),
    }

  case 'SET_DELIVERY_ADDRESS':
    return {
      ...state,
      deliveryAddress: action.payload.address,
      lastUpdatedAt: new Date(),
    }

  case 'SET_INVOICE_ADDRESS':
    return {
      ...state,
      invoiceAddress: action.payload.address,
      lastUpdatedAt: new Date(),
    }

  case 'SET_PICKUP_ADDRESS':
    return {
      ...state,
      pickupAddress: action.payload.address,
      lastUpdatedAt: new Date(),
    }

  case 'CLEAR_ORDER':
    return {
      defaultState
    }
    
  default:
    return state
  }
}

export default order