import React, { useState, useEffect } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { Helmet } from "react-helmet";
import { Form, Field } from "react-final-form";
import classNames from "classnames";

import "./OrderFlow.scss";
import {
  composeValidators,
  requiredValidator,
  postcodeValidator,
  houseNumberValidator,
  emailValidator,
} from "../../helpers/validators";
import Col from "react-bootstrap/Col";
import FormInputText from "../../components/order/Form/FormInputText";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, NavLink } from "react-router-dom";
const axios = require("axios");

function PageOrderBillingAddress() {
  const [addressEditable, setAddressEditable] = useState(false);
  const [addressLoading, setAddressLoading] = useState(false);

  const dispatch = useDispatch();
  const history = useHistory();
  const initialAddress = useSelector((state) => state.order.invoiceAddress);
  const pickupAddress = useSelector((state) => state.order.pickupAddress);
  const deliveryAddress = useSelector((state) => state.order.deliveryAddress);

  const onSubmit = async (form) => {
    const address =
      form.addressType !== "custom" ? { addressType: form.addressType } : form;

    await dispatch({
      type: "SET_INVOICE_ADDRESS",
      payload: { address },
    });
    history.push("/verzenden/controleer");
  };

  const hasDeliveryAddress = useSelector(
    (state) =>
      state.order.deliveryAddress && !!state.order.deliveryAddress.postcode
  );

  const preflightCheck = () => {
    if (!hasDeliveryAddress) {
      history.push("/verzenden/adres");
    }
  };

  useEffect(preflightCheck, []);

  useEffect(() => {
    if(!initialAddress || !initialAddress.addressType) {
      window.setFormValue('addressType', 'dropoff');
    }
  }, [initialAddress]);

  const getAddressByPostcode = async (
    postcode,
    housenumber,
    housenumberAddition
  ) => {
    if (!postcode || !housenumber) {
      setAddressLoading(false);
      return false;
    }

    try {
      setAddressLoading(true);
      const response = await axios.post(
        `${process.env.CONF_API_BASE}/address/find`,
        { postcode, housenumber: `${housenumber}${housenumberAddition || ""}` }
      );
      if (response.status === 200) {
        setAddressEditable(false);
        window.setFormValue("street", response.data.street);
        window.setFormValue("city", response.data.city);
      } else {
        setAddressEditable(true);
        window.setFormValue("street", null);
        window.setFormValue("city", null);
      }
      setAddressLoading(false);
    } catch (e) {
      console.error(e);
      setAddressEditable(true);
      window.setFormValue("street", null);
      window.setFormValue("city", null);
      setAddressLoading(false);
    }
  };

  return (
    <div>
      <Helmet>
        <title>
          Pakket verzenden - verzenden.nl: één verzendplatform voor grote en
          kleine pakketten
        </title>
        <link rel="canonical" href="https://www.verzenden.nl/zending/pakket" />
      </Helmet>

      <Container className="mt-3 pt-5">
        <div className="container-content">
          <h1>Factuuradres</h1>
          {/* <span className="order__subtitle">
            Via Swift kan je ontvanger zelf een aflevermoment kiezen.
          </span> */}
          {deliveryAddress && (
            <Form
              onSubmit={onSubmit}
              initialValues={initialAddress}
              mutators={{
                setValue: ([field, value], state, { changeValue }) => {
                  changeValue(state, field, () => value);
                },
              }}
              render={({
                handleSubmit,
                form,
                submitting,
                pristine,
                values,
                valid,
              }) => {
                window.setFormValue = form.mutators.setValue;
                return (
                  <form onSubmit={handleSubmit} className="mt-3">
                    {/* <Row className="mt-5 mb-2 pl-1">
                    <Col><h2>Ontvanger</h2></Col>
                  </Row> */}
                    {/* <Row className="mt-5 mb-2 pl-1">
                     <Col>
                      <h2>Ontvanger</h2>
                    </Col> *
                  </Row> */}

                    <>
                      <Row>
                        <Col xs={12} sm={6}>
                          <div className="form-group">
                            <label>Kies factuuradres</label>
                            <Field
                              name="addressType"
                              validate={requiredValidator}
                            >
                              {(props) => (
                                <select
                                  {...props.input}
                                  className="form-control"
                                >
                                  <option disabled>kies een adres</option>
                                  {pickupAddress && pickupAddress.street && (
                                    <option value="pickup">
                                      Afhaaladres (
                                      {`${pickupAddress.street}, ${pickupAddress.city}`}
                                      )
                                    </option>
                                  )}
                                  <option value="dropoff">
                                    Afleveradres (
                                    {`${deliveryAddress.street}, ${deliveryAddress.city}`}
                                    )
                                  </option>
                                  <option value="custom">Nieuw adres...</option>
                                </select>
                              )}
                            </Field>
                          </div>
                        </Col>
                      </Row>
                    </>
                    {values.addressType === "custom" && (
                      <div className="boxed-grey mt-4 mb-5">
                        <Row>
                          <Col xs={12} sm={5}>
                            <div className="form-group">
                              <label>Voornaam</label>
                              <Field
                                name="firstName"
                                validate={requiredValidator}
                              >
                                {(props) => <FormInputText {...props} />}
                              </Field>
                            </div>
                          </Col>
                          <Col xs={12} sm={7}>
                            <div className="form-group">
                              <label>Achternaam</label>
                              <Field
                                name="lastName"
                                validate={requiredValidator}
                              >
                                {(props) => <FormInputText {...props} />}
                              </Field>
                            </div>
                          </Col>

                          <Col xs={12} sm={12}>
                            <div className="form-group">
                              <label>Bedrijfsnaam</label>
                              <Field name="companyName">
                                {(props) => <FormInputText {...props} />}
                              </Field>
                            </div>
                          </Col>
                        </Row>

                        <Row>
                          <Col xs={12} sm={4}>
                            <div className="form-group">
                              <label>Telefoon</label>
                              <Field name="phoneNumber" type="tel">
                                {(props) => <FormInputText {...props} />}
                              </Field>
                            </div>
                          </Col>
                          <Col xs={12} sm={8}>
                            <div className="form-group">
                              <label>E-Mailadres</label>
                              <Field
                                name="email"
                                type="email"
                                validate={composeValidators(
                                  requiredValidator,
                                  emailValidator
                                )}
                              >
                                {(props) => <FormInputText {...props} />}
                              </Field>
                            </div>
                          </Col>
                        </Row>

                        <Row className="mt-5 mb-2 pl-1">
                          <Col>
                            <h2>Adres</h2>
                          </Col>
                        </Row>

                        <Row>
                          <Col xs={6} sm={3}>
                            <div className="form-group">
                              <label>postcode*</label>
                              <Field
                                name="postcode"
                                validate={composeValidators(
                                  requiredValidator,
                                  postcodeValidator
                                )}
                              >
                                {(props) => (
                                  <FormInputText
                                    {...props}
                                    onBlur={(e) => {
                                      getAddressByPostcode(
                                        values.postcode,
                                        values.housenumber,
                                        values.housenumberAddition
                                      );
                                    }}
                                  />
                                )}
                              </Field>
                            </div>
                          </Col>
                          <Col xs={6} sm={2}>
                            <div className="form-group">
                              <label>huisnummer*</label>
                              <Field
                                name="housenumber"
                                type="number"
                                validate={composeValidators(
                                  requiredValidator,
                                  houseNumberValidator
                                )}
                              >
                                {(props) => (
                                  <FormInputText
                                    onBlur={(e) => {
                                      getAddressByPostcode(
                                        values.postcode,
                                        values.housenumber,
                                        values.housenumberAddition
                                      );
                                    }}
                                    {...props}
                                  />
                                )}
                              </Field>
                            </div>
                          </Col>
                          <Col xs={7} sm={2}>
                            <div className="form-group">
                              <label>toevoeging</label>
                              <Field name="housenumberAddition">
                                {(props) => (
                                  <FormInputText
                                    onBlur={(e) => {
                                      getAddressByPostcode(
                                        values.postcode,
                                        values.housenumber,
                                        values.housenumberAddition
                                      );
                                    }}
                                    {...props}
                                  />
                                )}
                              </Field>
                            </div>
                          </Col>
                        </Row>

                        <Row>
                          <Col xs={12} sm={5}>
                            <div className="form-group">
                              <label>Straat</label>
                              <Field name="street" validate={requiredValidator}>
                                {(props) => (
                                  <FormInputText
                                    {...props}
                                    readonly={!addressEditable}
                                    loading={addressLoading}
                                  />
                                )}
                              </Field>
                            </div>
                          </Col>
                          <Col xs={12} sm={5}>
                            <div className="form-group">
                              <label>Plaats</label>
                              <Field name="city" validate={requiredValidator}>
                                {(props) => (
                                  <FormInputText
                                    {...props}
                                    readonly={!addressEditable}
                                    loading={addressLoading}
                                  />
                                )}
                              </Field>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    )}
                    <Row>
                      <Col className="text-right">
                        <NavLink
                          className="btn btn-outline-primary mr-sm-3 mb-2 mb-sm-0"
                          to={pickupAddress ? "/verzenden/ophaaladres" : "/verzenden/adres"}
                        >
                          terug
                        </NavLink>
                        <button
                          className={classNames("btn btn-primary", {
                            "btn-loading": false,
                          })}
                          disabled={!valid}
                        >
                          controleer zending
                        </button>
                      </Col>
                    </Row>
                  </form>
                );
              }}
            />
          )}
        </div>
      </Container>
    </div>
  );
}

export default PageOrderBillingAddress;
