import React, { useState, useEffect } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { Helmet } from "react-helmet";
import { Form, Field } from "react-final-form";
import classNames from "classnames";

import "./OrderFlow.scss";
import {
  composeValidators,
  requiredValidator,
  postcodeValidator,
  postcodeValidatorBe,
  houseNumberValidator,
  emailValidator,
  phoneValidator,
} from "../../helpers/validators";
import Col from "react-bootstrap/Col";
import FormInputText from "../../components/order/Form/FormInputText";
import { useHistory, NavLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import FormInputTextArea from "../../components/order/Form/FormInputTextArea";
const axios = require("axios");

function PageOrderDeliveryAddress() {
  const [addressEditable, setAddressEditable] = useState(false);
  const [addressLoading, setAddressLoading] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const initialAddress = useSelector((state) => state.order.deliveryAddress);
  const hasOffer = useSelector((state) =>
    state.order.parcel ? (!!state.order.parcel.weight && !!state.order.parcel.colli) : false
  );
  const provider = useSelector((state) => state.order.parcel.offerProvider);
  const onSubmit = async (form) => {
    await dispatch({
      type: "SET_DELIVERY_ADDRESS",
      payload: { address: form },
    });

    if (provider === "Swift") {
      history.push("/verzenden/ophaaladres");
    } else {
      history.push("/verzenden/factuuradres");
    }
  };

  const preflightCheck = () => {
    if (!hasOffer) {
      history.push("/verzenden/pakket");
    }
  };

  useEffect(preflightCheck, []);

  const getAddressByPostcode = async (
    postcode,
    housenumber,
    housenumberAddition
  ) => {
    if (!postcode || !housenumber) {
      setAddressLoading(false);
      return false;
    }
    const startpath = document.location.pathname

    try {
      setAddressLoading(true);
      const response = await axios.post(
        `${process.env.CONF_API_BASE}/address/find`,
        { postcode, housenumber: `${housenumber}${housenumberAddition || ""}` }
      );
      if (response.status === 200 && startpath === document.location.pathname) {
        setAddressEditable(false);
        window.setFormValue("street", response.data.street);
        window.setFormValue("city", response.data.city);
      } else {
        setAddressEditable(true);
        window.setFormValue("street", null);
        window.setFormValue("city", null);
      }
      setAddressLoading(false);
    } catch (e) {
      console.error(e);
      setAddressEditable(true);
      window.setFormValue("street", null);
      window.setFormValue("city", null);
      setAddressLoading(false);
    }
  };

  return (
    <div>
      <Helmet>
        <title>
          Pakket verzenden - verzenden.nl: één verzendplatform voor grote en
          kleine pakketten
        </title>
        <link rel="canonical" href="https://www.verzenden.nl/zending/adres" />
      </Helmet>

      <Container className="mt-3 pt-5 ">
        <div className="container-content">
          <h1>Afleveradres</h1>
          {/* <span className="order__subtitle">
            Via Swift kan je ontvanger zelf een aflevermoment kiezen.
          </span> */}
          <Form
            onSubmit={onSubmit}
            mutators={{
              // expect (field, value) args from the mutator
              setValue: ([field, value], state, { changeValue }) => {
                changeValue(state, field, () => value);
              },
            }}
            initialValues={initialAddress}
            render={({
              handleSubmit,
              form,
              submitting,
              pristine,
              values,
              valid,
              validate,
            }) => {
              window.setFormValue = form.mutators.setValue;
              return (
                <form onSubmit={handleSubmit} className="mt-3">
                  <Row className="mt-5 mb-2 pl-1">
                    <Col>
                      <h2>Ontvanger</h2>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12} sm={5}>
                      <div className="form-group">
                        <label>Voornaam</label>
                        <Field name="firstName" validate={requiredValidator}>
                          {(props) => <FormInputText {...props} />}
                        </Field>
                      </div>
                    </Col>
                    <Col xs={12} sm={7}>
                      <div className="form-group">
                        <label>Achternaam</label>
                        <Field name="lastName" validate={requiredValidator}>
                          {(props) => <FormInputText {...props} />}
                        </Field>
                      </div>
                    </Col>

                    <Col xs={12} sm={12}>
                      <div className="form-group">
                        <label>Bedrijfsnaam</label>
                        <Field name="companyName">
                          {(props) => <FormInputText {...props} />}
                        </Field>
                      </div>
                    </Col>
                  </Row>

                  <Row>
                    <Col xs={12} sm={4}>
                      <div className="form-group">
                        <label>Telefoon</label>
                        {/* <small className="order__subtitle">
                          gebruiken voor Track & Trace
                        </small> */}
                        <Field
                          name="phoneNumber"
                          type="tel"
                          validate={
                            values.choosesDate
                              ? composeValidators(
                                  requiredValidator, //eslint-disable-line
                                  phoneValidator //eslint-disable-line
                                ) //eslint-disable-line
                              : undefined
                          }
                        >
                          {(props) => <FormInputText {...props} />}
                        </Field>
                      </div>
                    </Col>
                    <Col xs={12} sm={8}>
                      <div className="form-group">
                        <label>E-mailadres</label>
                        <Field
                          name="email"
                          type="email"
                          validate={
                            values.choosesDate
                              ? composeValidators(
                                  requiredValidator, //eslint-disable-line
                                  emailValidator //eslint-disable-line
                                ) //eslint-disable-line
                              : undefined
                          }
                        >
                          {(props) => <FormInputText {...props} />}
                        </Field>
                      </div>
                    </Col>
                  </Row>

                  <Row>
                    <Col>
                      <div className="form-check">
                        <Field
                          name="choosesDate"
                          component="input"
                          type="checkbox"
                          id="choosesDate"
                          className="form-check-input"
                        >
                          {/* {(props) => <FormInputText {...props} />} */}
                        </Field>
                        <label className="form-check-label" htmlFor="choosesDate">
                          laat de ontvanger zelf het aflevermoment kiezen
                        </label>
                        {values.choosesDate && (
                          <>
                            <small
                              className="order__subtitle order__subtitle--green ml-2"
                              htmlFor="choosesDate"
                            >
                              de ontvanger zal via sms en e-mail geinformeerd
                              worden.
                            </small>
                          </>
                        )}
                      </div>
                    </Col>
                  </Row>

                  <Row className="mt-5 mb-2 pl-1">
                    <Col>
                      <h2>Adres</h2>
                    </Col>
                  </Row>

                  <Row>
                    <Col xs={12} sm={3}>
                      <div className="form-group">
                        <label>Land*</label>
                        <Field
                          name="country"
                          defaultValue="NL"
                          component="select"
                          className="form-control"
                        >
                          <option value="NL">Nederland</option>
                          <option value="BE">België</option>
                        </Field>       
                      </div>           
                    </Col>

                    <Col xs={6} sm={3}>
                      <div className="form-group">
                        <label>Postcode*</label>
                        <Field
                          name="postcode"
                          validate={composeValidators(
                            requiredValidator,
                            values.country === 'BE' ?  postcodeValidatorBe : postcodeValidator
                          )}
                        >
                          {(props) => (
                            <FormInputText
                              {...props}
                              onBlur={(e) => {
                                values.country === 'NL' && getAddressByPostcode(
                                  values.postcode,
                                  values.housenumber,
                                  values.housenumberAddition
                                );
                              }}
                            />
                          )}
                        </Field>
                      </div>
                    </Col>
                    <Col xs={6} sm={2}>
                      <div className="form-group">
                        <label>Huisnummer*</label>
                        <Field
                          name="housenumber"
                          validate={composeValidators(
                            requiredValidator,
                            houseNumberValidator
                          )}
                          type="number"
                        >
                          {(props) => (
                            <FormInputText
                              onBlur={(e) => {
                                values.country === 'NL' && getAddressByPostcode(
                                  values.postcode,
                                  values.housenumber,
                                  values.housenumberAddition
                                );
                              }}
                              {...props}
                            />
                          )}
                        </Field>
                      </div>
                    </Col>
                    <Col xs={7} sm={2}>
                      <div className="form-group">
                        <label>Toevoeging</label>
                        <Field name="housenumberAddition">
                          {(props) => (
                            <FormInputText
                              onBlur={(e) => {
                                values.country === 'NL' && getAddressByPostcode(
                                  values.postcode,
                                  values.housenumber,
                                  values.housenumberAddition
                                );
                              }}
                              {...props}
                            />
                          )}
                        </Field>
                      </div>
                    </Col>
                  </Row>

                  <Row>
                    <Col xs={12} sm={6}>
                      <div className="form-group">
                        <label>Straat</label>
                        <Field name="street" validate={requiredValidator}>
                          {(props) => (
                            <FormInputText
                              {...props}
                              readonly={values.country === 'NL' && !addressEditable}
                              loading={addressLoading}
                            />
                          )}
                        </Field>
                      </div>
                    </Col>
                    <Col xs={12} sm={6}>
                      <div className="form-group">
                        <label>Plaats</label>
                        <Field name="city" validate={requiredValidator}>
                          {(props) => (
                            <FormInputText
                              {...props}
                              readonly={values.country === 'NL' && !addressEditable}
                              loading={addressLoading}
                            />
                          )}
                        </Field>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12}>
                      <div className="form-group">
                        <label>Opmerkingen voor de bezorger</label>
                        <Field name="note">
                          {(props) => <FormInputTextArea {...props} />}
                        </Field>
                      </div>
                    </Col>
                  </Row>
                  <Row className="mt-3 mt-sm-5">
                    <Col className="text-right">
                      <NavLink
                        className="btn btn-outline-primary mr-sm-3 mb-2 mb-sm-0"
                        to="/verzenden/pakket"
                      >
                          terug
                      </NavLink>
                      <button
                        className={classNames("btn btn-primary", {
                          "btn-loading": false,
                        })}
                        type="submit"
                        disabled={!valid}
                      >
                        verder
                      </button>
                    </Col>
                  </Row>
                </form>
              );
            }}
          />
        </div>
      </Container>
    </div>
  );
}

export default PageOrderDeliveryAddress;
