import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { Helmet } from "react-helmet";

import "../My.scss";

import MyService from "../../../services/MyService";
import MyMenuHeader from "../../../components/My/MyMenuHeader";
import MyAddressBookSelector from "../../../components/My/forms/MyAddressBookSelector";
import MyOrderItemEdit from "../../../components/My/Order/MyOrderItemEdit";
import MyOrderItemView from "../../../components/My/Order/MyOrderItemView";
import { MoneyFormat } from "../../../helpers/shippingHelpers";
import { NavLink } from "react-router-dom";
import Loader from "../../../components/Loader/Loader";


function PageMyOrdersCreate() {
  const [pickupAddress, setPickupAddress] = useState({});
  const [profile, setProfile] = useState({})
  const [orderList, setOrderList] = useState([]);
  const [totalAmount, setTotalAmount] = useState(0);
  const [orderSaving, setOrderSaving] = useState(false);

  useEffect(() => {
    MyService.getProfile().then((profile) => {
      if(!profile.error) {
        setProfile(profile);
        setPickupAddress(profile.company.defaultPickupAddress);
        getOpenOrders();
      }
    });
  }, [])
  

  const getOpenOrders = async () => {
    const orders = await MyService.getOrderList({accepted: false, include: "products"});
    if(!orders.error && orders.orders && orders.orders.length) {
      setOrderList(orders.orders.reverse());
      setPickupAddress(orders.orders[0].pickupAddress);
      calculatePrice(orders.orders);
    }
  }

  const handleSaveOrder = async (orderData) => {
    const orderPickupAddress = (pickupAddress && pickupAddress.id) || pickupAddress
    setOrderSaving(true);
    const saveState = await MyService.createOrder({...orderData, pickupAddress: orderPickupAddress, autoAccept: false}); //eslint-disable-line
    if(saveState.error) {
      setOrderSaving(orderData);
    } else {
      await getOpenOrders();
      setOrderSaving(false);
    }
  };

  const calculatePrice = orders => {
    if(orders.length) {
      const price =  MoneyFormat(orders.reduce((a,b) => a+Number(b.amount),0));
      setTotalAmount(price);
    } else {
      setTotalAmount(0);
    }
  }

  const handleDelete = async (id) => {
    await MyService.deleteOrder(id);
    getOpenOrders();
  }

  return (
    <div>
      <Helmet>
        <title>
          Nieuwe zending - Mijn Verzenden - één verzendplatform voor grote en kleine pakketten
        </title>
        <link rel="canonical" href="https://www.verzenden.nl/mijn/adresboek" />
        <meta
          name="description"
          content="Wat wil je vandaag verzenden? Kies uit onze betrouwbare verzendpartners!"
        />
      </Helmet>

      <Container className="container-content container-my mt-5">
        <MyMenuHeader />
        <Row>
          <Col>
            <h1 className="d-inline mr-3">Nieuwe zending</h1>
          </Col>
        </Row>

        {profile.company && profile.company.allowOrder && <Row className="mt-4">
          <Col xs={12} sm={4} className="boxed-grey">
            <h2 className="mb-3">Afhaaladres</h2>
            {pickupAddress && (pickupAddress.street || typeof pickupAddress === "string") && <MyAddressBookSelector compactAddress value={orderList.length ? orderList[0].pickupAddress : pickupAddress} onChange={setPickupAddress} readOnly={orderList.length}/> }
          </Col>
        </Row>}

        <Row>
          {orderList.map(item => (
            <MyOrderItemView orderData={item} profile={profile} onDelete={handleDelete} key={item.id}/>
          ))}

          {orderSaving && <div className="myorderitemsave">
            {orderSaving && orderSaving.amount ? (
              <span className="myorderitemsave__error">
                Er is iets fout gegaan bij het opslaan van deze order.<br /><br />
                <a href="#a" onClick={(e) => {e.preventDefault(); handleSaveOrder(orderSaving)}}>probeer opnieuw</a> 
              </span>
            ) : 
              <Loader />
            }
          </div>}

          {profile.company && !profile.company.allowOrder && 
          <div className="alert alert-warning w-100 text-center mt-5">
            Je kan nog geen bestellingen plaatsen. <NavLink to="/mijn/profiel">Controleer of je afhaal- en factuuradres zijn ingevuld.</NavLink>
          </div>
          }
          
          {profile.company && profile.company.allowOrder && <MyOrderItemEdit profile={profile} onSave={handleSaveOrder} clickToAdd={!!orderList.length}  pickupAddress={pickupAddress} />}
        </Row>

        {profile.company && profile.company.allowOrder && <Row>
          <Col xs={12} md={{span:4, offset: 8}} className="mt-5 ">
            <div className="my-order-total">
              <Row>
                <Col xs={7}>Zendingen:</Col>
                <Col xs={5} className="text-right"><strong>{orderList.length}</strong></Col>
              </Row>
              <Row>
                <Col xs={7}>Totaalprijs:</Col>
                <Col xs={5} className="text-right"><strong>{totalAmount}</strong></Col>
              </Row>
            </div>
            <NavLink to="/mijn/betalen"><button className="btn btn-primary w-100 text-center mt-4" disabled={!(orderList.length && totalAmount)}>plaats bestelling &amp; reken af</button></NavLink>
          </Col>
        </Row>}
      </Container>
    </div>
  );
}

export default PageMyOrdersCreate;
