import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { Helmet } from "react-helmet";


import "./My.scss";

import MyService from "../../services/MyService";
import MyMenuHeader from "../../components/My/MyMenuHeader";

import { Form, Field } from "react-final-form";
import classNames from "classnames";

import {
  composeValidators,
  requiredValidator,
  postcodeValidator,
  houseNumberValidator,
  emailValidator,
} from "../../helpers/validators";
import FormInputText from "../../components/order/Form/FormInputText";
import FormInputTextArea from "../../components/order/Form/FormInputTextArea";
import { useHistory, useParams } from "react-router-dom";
import Loader from "../../components/Loader/Loader";
const axios = require("axios");


function PageMyAddressbookView() {

  const [addressData, setAddressData] = useState({loading: true});
  const [addressLoading, setAddressLoading] = useState(false);
  const [addressEditable, setAddressEditable] = useState(false);
  const [loading, setLoading] = useState(false);

  const history = useHistory();
  const params = useParams();

  const onSubmit = async (formdata) => {
    setLoading(true)
    const address = await MyService.updateAddress(params.guid, {...formdata, country: formdata.country ||  "NL"});
    // const address = false;
    if(address) {
      setLoading(false);
      history.push(`/mijn/adresboek/${address.id}`);
    } else {
      setLoading(false);
    }
  }

  const onDelete = async () => {
    setLoading(true)
    const address = await MyService.deleteAddress(params.guid);
    if(address) {
      history.push("/mijn/adresboek");
    }
    setLoading(false);
  }

  const getAddress = async () => {
    setAddressData({loading: true});

    const address = await MyService.getAddress(params.guid);
    if(address) {
      setAddressData(address);
    } else {
      setAddressData({error:true});
    }
  }

  useEffect(() => {
    getAddress()
  },[])

  const getAddressByPostcode = async (
    postcode,
    housenumber,
    housenumberAddition
  ) => {

    if (!postcode || !housenumber) {
      setAddressLoading(false);
      return false;
    }

    try {
      setAddressLoading(true);
      const response = await axios.post(
        `${process.env.CONF_API_BASE}/address/find`,
        { postcode, housenumber: `${housenumber}${housenumberAddition || ""}` }
      );
      if (response.status === 200) {
        setAddressEditable(false);
        window.setFormValue("street", response.data.data.street);
        window.setFormValue("city", response.data.data.city);
      } else {
        setAddressEditable(true);
        window.setFormValue("street", null);
        window.setFormValue("city", null);
      }
      setAddressLoading(false);
    } catch (e) {
      console.error(e);
      setAddressEditable(true);
      window.setFormValue("street", null);
      window.setFormValue("city", null);
      setAddressLoading(false);
    }
  };
  
  return (
    <div>
      <Helmet>
        <title>
          Bewerk adres - Mijn Verzenden - één verzendplatform voor grote en kleine pakketten
        </title>
        <link rel="canonical" href="https://www.verzenden.nl/mijn/adresboek" />
        <meta
          name="description"
          content="Wat wil je vandaag verzenden? Kies uit onze betrouwbare verzendpartners!"
        />
      </Helmet>

      <Container className="container-content container-my mt-5">
        <MyMenuHeader />
        <Row>
          <Col>
            <h1>Bewerk adres</h1></Col>
        </Row>
        <Row>

          <Col>
            {addressData.loading && <Loader />}
            {addressData.error &&               
          <div
            className="alert alert-warning alert-auto mt-5 text-center w-100"
            role="alert"
          >
                  Fout bij ophalen van het adres. <a href="#0" onClick={getAddress}>probeer opnieuw</a>
          </div>}
            {!addressData.error && !addressData.loading && <Form
              onSubmit={onSubmit}
              initialValues={addressData}
              mutators={{
                setValue: ([field, value], state, { changeValue }) => {
                  changeValue(state, field, () => value);
                },
              }}
              render={({
                handleSubmit,
                form,
                submitting,
                pristine,
                values,
                valid,
              }) => {
                window.setFormValue = form.mutators.setValue;
                return (
                  <form onSubmit={handleSubmit} className="mt-3">

                    <div>
                      <Row>
                        <Col xs={12} sm={5}>
                          <div className="form-group">
                            <label>Voornaam</label>
                            <Field
                              name="firstName"
                              validate={requiredValidator}
                            >
                              {(props) => <FormInputText {...props} />}
                            </Field>
                          </div>
                        </Col>
                        <Col xs={12} sm={7}>
                          <div className="form-group">
                            <label>Achternaam</label>
                            <Field
                              name="lastName"
                              validate={requiredValidator}
                            >
                              {(props) => <FormInputText {...props} />}
                            </Field>
                          </div>
                        </Col>

                        <Col xs={12} sm={12}>
                          <div className="form-group">
                            <label>Bedrijfsnaam</label>
                            <Field name="companyName">
                              {(props) => <FormInputText {...props} />}
                            </Field>
                          </div>
                        </Col>
                      </Row>

                      <Row>
                        <Col xs={12} sm={4}>
                          <div className="form-group">
                            <label>Telefoon</label>
                            <Field name="phoneNumber" type="tel">
                              {(props) => <FormInputText {...props} />}
                            </Field>
                          </div>
                        </Col>
                        <Col xs={12} sm={8}>
                          <div className="form-group">
                            <label>E-Mailadres</label>
                            <Field
                              name="email"
                              type="email"
                              validate={emailValidator}
                            >
                              {(props) => <FormInputText {...props} />}
                            </Field>
                          </div>
                        </Col>
                      </Row>

                      <Row className="mt-5 mb-2 pl-1">
                        <Col>
                          <h2>Adres</h2>
                        </Col>
                      </Row>

                      <Row>
                        <Col xs={6} sm={3}>
                          <div className="form-group">
                            <label>postcode*</label>
                            <Field
                              name="postcode"
                              validate={composeValidators(
                                requiredValidator,
                                postcodeValidator
                              )}
                            >
                              {(props) => (
                                <FormInputText
                                  {...props}
                                  onBlur={(e) => {
                                    getAddressByPostcode(
                                      values.postcode,
                                      values.housenumber,
                                      values.housenumberAddition
                                    );
                                  }}
                                />
                              )}
                            </Field>
                          </div>
                        </Col>
                        <Col xs={6} sm={2}>
                          <div className="form-group">
                            <label>huisnummer*</label>
                            <Field
                              name="housenumber"
                              type="number"
                              validate={composeValidators(
                                requiredValidator,
                                houseNumberValidator
                              )}
                            >
                              {(props) => (
                                <FormInputText
                                  onBlur={(e) => {
                                    getAddressByPostcode(
                                      values.postcode,
                                      values.housenumber,
                                      values.housenumberAddition
                                    );
                                  }}
                                  {...props}
                                />
                              )}
                            </Field>
                          </div>
                        </Col>
                        <Col xs={7} sm={2}>
                          <div className="form-group">
                            <label>toevoeging</label>
                            <Field name="housenumberAddition">
                              {(props) => (
                                <FormInputText
                                  onBlur={(e) => {
                                    getAddressByPostcode(
                                      values.postcode,
                                      values.housenumber,
                                      values.housenumberAddition
                                    );
                                  }}
                                  {...props}
                                />
                              )}
                            </Field>
                          </div>
                        </Col>
                      </Row>

                      <Row>
                        <Col xs={12} sm={5}>
                          <div className="form-group">
                            <label>Straat</label>
                            <Field name="street" validate={requiredValidator}>
                              {(props) => (
                                <FormInputText
                                  {...props}
                                  readonly={!addressEditable}
                                  loading={addressLoading}
                                />
                              )}
                            </Field>
                          </div>
                        </Col>
                        <Col xs={12} sm={5}>
                          <div className="form-group">
                            <label>Plaats</label>
                            <Field name="city" validate={requiredValidator}>
                              {(props) => (
                                <FormInputText
                                  {...props}
                                  readonly={!addressEditable}
                                  loading={addressLoading}
                                />
                              )}
                            </Field>
                          </div>
                        </Col>
                        <Col xs={12} >
                          <div className="form-group mt-5">
                            <label>Opmerkingen voor de bezorger</label>
                            <Field name="note">
                              {(props) => (
                                <FormInputTextArea
                                  {...props}
                                />
                              )}
                            </Field>
                          </div>
                        </Col>
                      </Row>
                
                

                      <Row className="mt-5">
                        <Col xs={6}>
                          <button
                            className={classNames("btn btn-secondary", {
                              "btn-loading": loading,
                            })}
                            type="button"
                            onClick={onDelete}
                          >
                          verwijder
                          </button>
                        </Col>
                        <Col className="text-right" xs={6}>
                          <button
                            className={classNames("btn btn-primary", {
                              "btn-loading": loading,
                            })}
                            disabled={!valid}
                            type="submit"
                          >
                          opslaan
                          </button>
                        </Col>
                      </Row>
                    </div>
                  </form>
                );
              }}
            />
            }
          </Col>
        </Row>
      
        
      </Container>
    </div>
  );
}

export default PageMyAddressbookView;
