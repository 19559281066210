import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { Helmet } from "react-helmet";

import "../My.scss";
import { NavLink } from "react-router-dom";

import MyService from "../../../services/MyService";
import MyMenuHeader from "../../../components/My/MyMenuHeader";

import moment from 'moment';
import 'moment/locale/nl';

function PageMyOrders() {
  const [orders, setOrders] = useState([]);
  const [openOrders, setOpenOrders] = useState([]);
  const [dataLoading, setDataLoading] = useState(true);

  useEffect(() => {
    MyService.getOrderList().then((orders) => {setOrders(orders); setDataLoading(false);});
    MyService.getOrderList({accepted: false}).then((orders) => {setOpenOrders(orders);});
  }, [])


  const CompactAddressBlock = ({ address }) => (
    address ? 
      <>
        <strong>
          {address.companyName ? address.companyName : `${address.firstName} ${address.lastName}`}
        </strong>
        <br />
        {`${address.street} ${address.housenumber}${
          address.housenumberAddition || ""
        }`}
        <br />
        {`${address.postcode} ${address.city}`}
        {address.country === 'BE' && (<><br />België</>)}

      </> : <> </>
  );

  return (
    <div>
      <Helmet>
        <title>
          Zendingen - Mijn Verzenden - één verzendplatform voor grote en kleine pakketten
        </title>
        <link rel="canonical" href="https://www.verzenden.nl/mijn/adresboek" />
        <meta
          name="description"
          content="Wat wil je vandaag verzenden? Kies uit onze betrouwbare verzendpartners!"
        />
      </Helmet>

      <Container className="container-content container-my mt-5">
        <MyMenuHeader />
        <Row>
          <Col>
            <h1 className="d-inline mr-3">Zendingen</h1>
            <NavLink className="headerlink" to="/mijn/orders/nieuw">nieuwe zending</NavLink>
          </Col>
        </Row>
        {openOrders.recordsFiltered > 0 && <Row>
          <Col>
            <div className="alert alert-info alert-auto mb-2 w-100 mt-5">
              Je hebt nog <strong>{openOrders.recordsFiltered}</strong> {openOrders.recordsFiltered === 1 ? "zending" : "zendingen"} klaarstaan. Wil je deze <NavLink to="/mijn/orders/nieuw">afronden</NavLink>?
            </div>
          </Col>
        </Row>}
        <Row className="mt-4">
          <Col>
            <div className="my-table__wrapper">
              <table className="my-table">
                <tr className="my-table__header">
                  <th width="20%">zending</th>
                  <th width="20%">aanmaakdatum</th>
                  <th width="30%">afleveradres</th>
                  <th width="30%">afhaaladres</th>
                  {/* <th width="10%">acties</th> */}
                </tr>
                {dataLoading && <tr>
                  <td><span className="my-table__skeleton"></span></td>
                  <td><span className="my-table__skeleton"></span></td>
                  <td><span className="my-table__skeleton"></span></td>
                  <td><span className="my-table__skeleton"></span></td>
                </tr>}
                {orders.orders && orders.orders.map((order) =>
            
                  <tr>
                    <td>
                      <NavLink to={`/mijn/orders/${order.id}`} className="cta cta--large">{order.number}</NavLink><br />
                      <small>{order.reference}</small>
                    </td>
                    <td><NavLink to={`/mijn/orders/${order.id}`}>{moment(order.createdAt).calendar()}</NavLink></td>
                    <td><NavLink to={`/mijn/orders/${order.id}`}><CompactAddressBlock address={order.dropoffAddress} /> </NavLink></td>
                    <td><NavLink to={`/mijn/orders/${order.id}`}><CompactAddressBlock address={order.pickupAddress} /> </NavLink></td>
                  </tr>)}
              </table>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default PageMyOrders;
