import React, { useState } from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

import "./MyOrderItems.scss";
import "../../../pages/order/OrderFlow.scss";
import { MoneyFormat } from "../../../helpers/shippingHelpers";
import MyAddressBookSelector from "../forms/MyAddressBookSelector";

import classNames from "classnames";



function MyOrderItemView({orderData, profile, onDelete}) {
  const providers = {postnl: "PostNL", swift: "Swift"};

  const getProductGroup = () => {
    // if(orderData.provider) {
    //   return providers[orderData.provider];
    // }
    const availableGroups = profile.company && profile.company.productGroups && profile.company.productGroups.data;
    const localGroups = [...availableGroups, {key: "swift", name: "Swift standaad"}, {key: "postnl", name: "PostNL"}]
    const group = localGroups.find(foundGroup => foundGroup.key === (orderData.products && orderData.products[0] && orderData.products[0].productGroup)) || {key: null, name: "Swift standaard"};
    return group.name;
  }

  

  const handleDelete = (e) => {
    e.preventDefault();
    setRemoving(true);
    setTimeout(() => {onDelete(orderData.id)}, 600);
  }

  const [removing, setRemoving] = useState(false);

  return orderData ? (
    <>
      <div className={classNames("myorderitemview", {"myorderitemview--removed": removing})} >
        <Row>
          <Col xs={12} md={4} className="pr-4">
            <h2 className="mb-3">Afleveradres</h2>
            <MyAddressBookSelector compactAddress value={orderData && orderData.dropoffAddress}  readOnly />
          </Col>
          <Col xs={12} md={3} className="pr-4">
            <h2>Zending</h2>
            {getProductGroup() !== 'Swift' && (getProductGroup())}
            


            {orderData.reference && 
            <>
              <br />
              <br />
              <strong>Referentie</strong><br />
              {orderData.reference}
            </>
            }
            {orderData.article && 
            <>
              <br /><br />
              <strong>Artikelnummer</strong><br />
              {orderData.article}
            </>
            }

          </Col>

          <Col xs={12} md={3} className="pr-4 pt-4">
            {orderData.colli && 
            <>
              <strong>Colli</strong><br />
              {orderData.colli} stuk(s)
            </>
            }
            {orderData.dimensions && orderData.dimensions.weight && 
            <>
              <br />
              <br />
              <strong>Gewicht</strong><br />
              {orderData.dimensions && orderData.dimensions.weight} kg
            </>
            }
       

          </Col>
          <Col xs={12} md={2} >
            <h2>Vervoerder</h2>
            <span>{providers[orderData.provider]}</span>
          </Col>
        </Row> 

        <div className="myorderitemview__ctabar">
          <a href="#d" onClick={handleDelete}>verwijder</a>
            &nbsp;&nbsp;&mdash;&nbsp;&nbsp;
          <strong>{MoneyFormat(orderData.amount)}</strong>
        </div>
      </div>
    </>
  ): <div></div>;
}

export default MyOrderItemView;
