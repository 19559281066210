import React, { useState, useEffect, useRef } from "react";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { Helmet } from "react-helmet";

import "./FaqList.scss";
import Axios from "axios";
import FaqListItem from "../../components/faq/FaqListItem";
import FaqContactForm from "../../components/faq/FaqContactForm/FaqContactForm";

// import iconLetter from "../assets/shipment-letter.svg";

function FaqList() {
  const [faqData, setFaqData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const loadArticles = async () => {
    try {
      const request = await Axios.get(`${process.env.CONF_API_BASE}/faq`);
      setFaqData(request.status === 200 ? request.data : {});
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    loadArticles();

    window.addEventListener("hashchange", handleHashChange, false);
    return () => { window.removeEventListener("hashchange", handleHashChange, false) };

  }, []);

  const faqRef = useRef(null)

  const handleHashChange = (e) => {
    if(window.location.hash === "#contact") {
      scrollToRef();
    }
  }
  const scrollToRef = (e = null) => {
    e && e.preventDefault();
    window.scrollTo({top: faqRef.current.offsetTop - 160, left: 0,   behavior: 'smooth'});
  }

  const handleSearch = (e) => {
    if (e && e.target) {
      if (e.target.value.length > 2) {
        setSearchQuery(e.target.value);
      } else {
        setSearchQuery(null);
      }
    }
  };

  return (
    <div>
      <Helmet>
        <title>
          FAQ - verzenden.nl: één verzendplatform voor grote en kleine pakketten
        </title>
        <link rel="canonical" href="https://www.verzenden.nl/" />
        <meta name="description" content="Veelgestelde vragen &amp; contact" />
      </Helmet>

      <Container className="mt-5 pt-5 container-content">
        <Row>

          <Col xs={12} sm={8}>
            <h1 className="text-green text-uppercase">Veelgestelde vragen</h1>
            <span className="order__subtitle mt-4 mb-4">Staat uw vraag hier niet tussen? neem dan contact op via het <a href="#/" onClick={scrollToRef}>contactformulier</a></span>

            <div className="boxed-grey mt-4">
              <input
                type="text"
                placeholder="zoeken door FAQ"
                className="form-control"
                onChange={handleSearch}
              />
            </div>
          </Col>


          
          <Col xs={12}>
            {faqData.map((faqGroup) => (
              <div>
                <h2 className="mt-5 mb-2">{faqGroup.name}</h2>
                {faqGroup.questions.map((item) => (
                  <FaqListItem key={item.question} item={item} searchQuery={searchQuery} />
                ))}
              </div>
            ))}
          </Col>
        </Row>
                  
        <h1 className="mt-5" ref={faqRef} >Contactformulier</h1>
        <span className="order__subtitle">Staat uw vraag er niet bij? Neem dan contact op via onderstaand formulier.</span>

        <FaqContactForm  />
      </Container>
    </div>
  );
}

export default FaqList;
