import React, { useState, useEffect } from "react";
import Container from "react-bootstrap/Container";
// import Row from "react-bootstrap/Row";
import { Helmet } from "react-helmet";

import "./PageTrackTrace.scss";
import Axios from "axios";
import { useParams } from "react-router-dom";
import Loader from "../../../components/Loader/Loader";
import TrackTraceError from "../../../components/tracking/TrackTraceError/TrackTraceError";
import TrackTraceStatus from "../../../components/tracking/TrackTraceStatus/TrackTraceStatus";

function PageTrackTrace() {
  const [shipmentStatus, setShipmentStatus] = useState({ loading: true });

  const { barcode, postcode } = useParams();

  const getShipmentStatus = async () => {
    try {
      const response = await Axios.get(
        `${process.env.CONF_API_BASE}/tracking?number=${barcode}&postcode=${postcode}`,
        { validateStatus: () => true }
      );
      setShipmentStatus(
        (response.status === 200 && { data: response.data, loading: false, error: false }) || {
          loading: false,
          error: true,
          status: response.status,
          statusText: response.statusText
        }
      );
      
    } catch (e) {
      console.log(e)
      return false;
    }
  };

  useEffect(() => {
    getShipmentStatus();
  }, []);

  return (
    <div>
      <Helmet>
        <title>
          Track & Trace - verzenden.nl: één verzendplatform voor grote en kleine
          pakketten
        </title>
        <link
          rel="canonical"
          href="https://www.verzenden.nl/track"
        />
      </Helmet>

      <Container className="mt-3 pt-5 ">
        <div className="container-content">
          <h1 className="text-center">Track &amp; Trace</h1>
          {shipmentStatus.loading && <Loader /> }
          {shipmentStatus.error && <TrackTraceError status={shipmentStatus.status} statusText={shipmentStatus.statusText} />}
          {!shipmentStatus.loading && shipmentStatus.data && <TrackTraceStatus status={shipmentStatus.data} />}
        </div>
      </Container>
    </div>
  );
}

export default PageTrackTrace;
