import React, { useState } from "react";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { Helmet } from "react-helmet";

import {useHistory, useParams} from 'react-router-dom'

import classNames from 'classnames';

import FormInputText from "../../components/order/Form/FormInputText";
import MyService from '../../services/MyService'
import { Form, Field } from "react-final-form";

import { requiredValidator} from "../../helpers/validators";

import "./PageRegister.scss";

// import iconLetter from "../assets/shipment-letter.svg";

function PageActivateAccount() {
  const [activationStatus, setActivationStatus] = useState();
  const history = useHistory();
  const params = useParams();


  const handleActivate = async (data) => {
    setActivationStatus('loading')
    const register = await MyService.activateAccount(data.activationcode, params.guid);
    if(!register) {
      setActivationStatus('error')
    } else {
      setActivationStatus()
      history.push(`/login/?m=activated`);
    }
  };

  return (
    <div>
      <Helmet>
        <title>
          Activeer je account - verzenden.nl: één verzendplatform voor grote en kleine pakketten
        </title>
        <link rel="canonical" href="https://www.verzenden.nl/" />
        <meta name="description" content="Login op uw Verzenden.nl accounnt" />
      </Helmet>

      <Container className="mt-5 pt-5 container-content">
        <Row>

          <Col xs={12} sm={12}>
            {activationStatus !== 'error' && (<div
              className="alert alert-success alert-auto mb-3 w-100"
              role="alert"
            >
              <strong>Registratie geslaagd!</strong> Controleer je e-mail voor een activatiecode.
            </div>)}

            {activationStatus === 'error' && (<div
              className="alert alert-warning alert-auto mb-3 w-100"
              role="alert"
            >
              <strong>Ongeldige activatiecode</strong>
            </div>)}
            

            <Form
              onSubmit={handleActivate}

              render={({
                handleSubmit,
                form,
                submitting,
                pristine,
                values,
                valid,
              }) => {
                return (
                  <form onSubmit={handleSubmit} className="mt-3">
                    <Row>
                      <Col xs={12} className="text-center">
                        <div className="form-group">
                          <h1 className="mt-3 mb-3">Activatiecode</h1>
                          <Field name="activationcode"  validate={requiredValidator}>
                            {(props) => <FormInputText  {...props} className="activatecode" shortValidation maxLength="6"/>}
                          </Field>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col className="text-center mt-4">
                        <button
                          className={classNames("btn btn-primary", {
                            "btn-loading": activationStatus === 'loading' ,
                          })}
                          disabled={!valid}
                        >
                        activeren
                        </button>
                      </Col>
                    </Row>

                  </form>
                )}} />
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default PageActivateAccount;
