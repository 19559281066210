import * as Sentry from "@sentry/react";
import ApiService from "./ApiService";
const axios = require("axios");
var jwt = require("jsonwebtoken");


const api = new ApiService();


const userLogin =  async (email, password) => {
  try {
    const request = await axios.post(`${process.env.CONF_API_HOST}/oauth/v2/token`, 
      {
        grant_type: "password",
        client_id: "1_1fwu8de4knfo84k8ckkw4ggwwc0g4o04kss4gksc0o0kosgwg0",
        client_secret: "12xdk8o4d4w00kg0ow8k4s88ocskkw4o844og8kssgoooook4c",
        username: email,
        password
      });

    const {data, status} = request;
    if(status === 401) {
      return false;
    } else if(status === 200) {

      const user = await axios.get(`${process.env.CONF_MY_API_BASE}/users/me`, {headers: {'Authorization': `Bearer ${data.access_token}`}});
      const userData = user.data
      const token = jwt.sign({...data, ...userData}, process.env.CONF_TOKEN_SECRET, { expiresIn: data.expires_in - 120 });
        
      Sentry.setUser({ email, id: userData.id, firstName: userData.firstName, lastName: userData.lastName, username: `${userData.firstName} ${userData.lastName}`});

      localStorage.setItem('authToken', token);
      localStorage.setItem('refreshToken', data.refresh_token);
      return true;
    }

  } catch(e) {
    return false
  }
}

const getUserDetails = () => {
  if(!localStorage.getItem('authToken')) return false;
  try {
    const data = jwt.verify(localStorage.getItem('authToken')  ,process.env.CONF_TOKEN_SECRET)
    if (!data) return false;
    const user = {firstName: data.firstName, prefix: data.prefix, lastName: data.lastName, email: data.email, id: data.id, roles: data.roles, username: `${data.firstName} ${data.lastName}`};
    Sentry.setContext("user", user);
    return user;
  } catch(e) {
    return false
  }
}

const userLogout = () => {
  localStorage.setItem('authToken', null);
  delete localStorage['authToken'];
  window.location = '/login?m=logout';
}

const createAccount = async (accountDetails) => {
  const registerData = {
    ...accountDetails, 
    company: {
      name: accountDetails.companyname, 
      invoiceAddress: {
        street: accountDetails.street,
        housenumber:accountDetails.housenumber,
        housenumberAddition: accountDetails.housenumberAddition,
        postcode: accountDetails.postcode,
        city: accountDetails.city,
        country: accountDetails.country ||  "NL",
        firstName: accountDetails.firstName,
        lastName: accountDetails.lastName,
        companyName: accountDetails.companyName,
      }
    },
  }
  const dataOrError = await api.post(`${process.env.CONF_MY_API_BASE}/users/register`, registerData, false);
  return dataOrError;

}
const activateAccount = async (activationCode, id) => await api.post(`${process.env.CONF_MY_API_BASE}/users/activate`, {activationCode, id})

const getUserMy = async () =>  await api.get(`${process.env.CONF_MY_API_BASE}/users/me`)

/* ADDRESSBOOK */

const getAddressbook = async () => await api.get(`${process.env.CONF_MY_API_BASE}/addresses`)
const getAddress = async (guid) => await api.get(`${process.env.CONF_MY_API_BASE}/addresses/${guid}`)
const deleteAddress = async (guid) =>  await api.delete(`${process.env.CONF_MY_API_BASE}/addresses/${guid}`)
const createAddress = async (address) =>  await api.post(`${process.env.CONF_MY_API_BASE}/addresses/create`, address)
const updateAddress = async (guid, address) =>  await api.post(`${process.env.CONF_MY_API_BASE}/addresses/${guid}`, address)

/* ORDERS */
const getOrderList = async (requestParams) => {
  let params = requestParams || {};
  if(!requestParams || (requestParams && requestParams.accepted !== false)) {
    params.accepted = true
  }
  const urlParams = new URLSearchParams(params).toString()
  return await api.get(`${process.env.CONF_MY_API_BASE}/orders?${urlParams}`);
}

const getOrder = async (guid) => await api.get(`${process.env.CONF_MY_API_BASE}/orders/${guid}`);
const createOrder = async (order) => await api.post(`${process.env.CONF_MY_API_BASE}/orders`, order)
const deleteOrder = async (guid) => await api.delete(`${process.env.CONF_MY_API_BASE}/orders/${guid}`);
const getPickupDates = async (postcode) => await api.get(`${process.env.CONF_API_BASE}/pickup/options?postcode=${postcode}`, false);

/* INVOICE & PAYMENT */
const getInvoiceList = async () => await api.get(`${process.env.CONF_MY_API_BASE}/invoices`);
const getPaymentMethods = async () => await api.get(`${process.env.CONF_API_BASE}/payment/methods`)
const getIdealIssuers = async () => await api.get(`${process.env.CONF_API_BASE}/payment/ideal/issuer`)
const createPayment = async (data) => await api.post(`${process.env.CONF_MY_API_BASE}/orders/pay`, data)
const acceptPayment = async (order) => await api.post(`${process.env.CONF_MY_API_BASE}/orders/accept`, order)

/* PROFILE */
const getProfile = async () => await api.get(`${process.env.CONF_MY_API_BASE}/users/me`);
const saveProfile = async (profile) => await api.post(`${process.env.CONF_MY_API_BASE}/users/me`, profile);
const setInvoiceAddress = async (address) => await api.post(`${process.env.CONF_MY_API_BASE}/company/update`, {invoiceAddress: address});
const setPickupAddress = async (address) => await api.post(`${process.env.CONF_MY_API_BASE}/company/update`, {defaultPickupAddress: address});

export default {userLogin, userLogout, getUserDetails, getUserMy, getAddressbook, getAddress, deleteAddress, updateAddress, createAddress, getOrderList, getOrder, createOrder, getPickupDates, getPaymentMethods, getIdealIssuers, createPayment, acceptPayment, deleteOrder, getInvoiceList ,createAccount, activateAccount, getProfile, saveProfile, setInvoiceAddress, setPickupAddress}