import React from 'react';
import ReactDOM from 'react-dom';
import 'bootstrap/dist/css/bootstrap.min.css';

import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

import { Provider } from 'react-redux'
import { createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';

import rootReducer from './reducers'

import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web
import { PersistGate } from 'redux-persist/integration/react'

import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";


// import TagManager from 'react-gtm-module'


const persistConfig = {
  key: 'root',
  storage,
}

const persistedReducer = persistReducer(persistConfig, rootReducer)
const store = createStore(persistedReducer, composeWithDevTools());
const persistor = persistStore(store);
console.log(process.env.CONF_SENTRY_ENV)

Sentry.init({
  dsn: "https://f23dea76c5df931857092b418e5567c1@sentry.lab.fexxio.net/14",
  release: process.env.CONF_GIT_RELEASE,
  integrations: [new Integrations.BrowserTracing()],
  environment: process.env.CONF_SENTRY_ENV,

  beforeSend(event, hint) {
    // Check if it is an exception, and if so, show the report dialog
    if (event.exception) {
      // Sentry.showReportDialog({ eventId: event.event_id, 
      //   lang:"nl",
      //   title: "Het lijkt er op dat er iets mis gaat!",
      //   subtitle: "Ons team is op de hoogte gesteld.",
      //   subtitle2: "Wil je ons helpen? geef aan wat er precies is misgegaan.",
      //   labelName: "Naam",
      //   labelEmail: "E-mailadres",
      //   labelClose: "sluit",
      //   labelComments: "Wat is er misgegaan?",
      //   labelSubmit: "verzenden",
      //   errorFormEntry: "geen opmerking ingevuld",
      
      //   successMessage: "Dankjewel voor het helpen!",
      //   user: event.contexts && event.contexts.user ? {
      //     name: event.contexts.user.firstName+ ' ' + event.contexts.user.lastName,
      //     email: event.contexts.user.email
      //   } : null
      // });
    }
    return event;
  },

  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 1.0,
});


ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <React.StrictMode>
        <App />
      </React.StrictMode>
    </PersistGate>
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
