import React from "react";
import classNames from "classnames";

function FormInputTextArea({
  input,
  meta,
  className,
  placeholder,
  disabled,
  readonly,
  onBlur,
  loading,
  rows
}) {
  return (
    <>
      <textarea
        {...input}
        rows={rows || '4'}
        placeholder={placeholder}
        className={classNames(`form-control ${className}`, {
          "is-invalid": meta.error && meta.touched && !readonly,
          "is-valid": !meta.error && meta.touched && meta.dirty && !readonly,
        })}
        disabled={disabled}
        readOnly={readonly}
        onBlur={(e) => {
          input.onBlur(e);
          onBlur && onBlur(e);
        }}
      />
      {meta.touched && meta.error && !readonly && (
        <span
          className="form-error"
          role="alert"
          dangerouslySetInnerHTML={{ __html: meta.error }}
        ></span>
      )}
      {loading && <span className="form-control-loading"></span>}
    </>
  );
}

export default FormInputTextArea;
