import React from "react";


import "./TrackTraceError.scss";
const ErrorMessages = require("./ErrorList.json");

function TrackTraceError({status, title, statusText}) {
  const errorTitle = ErrorMessages[status] ? ErrorMessages[status].title : ErrorMessages[999].title;
  const errorMessage = ErrorMessages[status] ? ErrorMessages[status].message : ErrorMessages[999].message;

  return (
    <div className="tracktraceerror mt-5 text-center">
      <h2 className="mb-3">{errorTitle}</h2>
      <span dangerouslySetInnerHTML={{__html: errorMessage}}></span>
    </div>
  );
}

export default TrackTraceError;
