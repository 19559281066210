import React, { useEffect } from "react";
import { Helmet } from "react-helmet";

import MyService from '../../services/MyService'

// import iconLetter from "../assets/shipment-letter.svg";

function PageLogout() {
  const logout =  (data) => {
    MyService.userLogout();
  };

  useEffect(logout, []);

  return (
    <div>
      <Helmet>
        <title>
          Uitloggen - verzenden.nl: één verzendplatform voor grote en kleine pakketten
        </title>
        <link rel="canonical" href="https://www.verzenden.nl/" />
        <meta name="description" content="Uitloggen -  Verzenden.nl account" />
      </Helmet>
    </div>
  );
}

export default PageLogout;
