
import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { Helmet } from "react-helmet";


// import "./My.scss";

import MyService from "../../../services/MyService";
import MyMenuHeader from "../../../components/My/MyMenuHeader";

import { Form, Field } from "react-final-form";

import {
  requiredValidator,
  emailValidator,
} from "../../../helpers/validators";
import FormInputText from "../../../components/order/Form/FormInputText";
import Loader from "../../../components/Loader/Loader";
import MyAddressBookSelector from "../../../components/My/forms/MyAddressBookSelector";
const axios = require("axios");


function PageMyProfile() {

  const [profileData, setProfileData] = useState({loading: true});
  const [addressLoading, setAddressLoading] = useState(false); //eslint-disable-line
  const [addressEditable, setAddressEditable] = useState(false);//eslint-disable-line
  const [loading, setLoading] = useState(false);//eslint-disable-line

  const [pickupAddressEditable, setPickupAddressEditable] = useState(false);//eslint-disable-line
  const [invoiceAddressEditable, setInvoiceAddressEditable] = useState(false);//eslint-disable-line



  const onSubmit = async (formdata) => {
    setLoading(true)
    const user = await MyService.saveProfile({...formdata, company: {name: formdata.company.name}});
    if(user) {
      setLoading(false);
    } else {
      setLoading(false);
    }
  }

  const getProfile = async () => {
    setProfileData({loading: true});

    const profile = await MyService.getProfile();
    if(!profile.error) {
      setProfileData(profile);
    } else {
      setProfileData({error:true});
    }
  }

  useEffect(() => {
    getProfile()
  },[])

  const getAddressByPostcode = async (//eslint-disable-line
    postcode,
    housenumber,
    housenumberAddition
  ) => {

    if (!postcode || !housenumber) {
      setAddressLoading(false);
      return false;
    }

    try {
      setAddressLoading(true);
      const response = await axios.post(
        `${process.env.CONF_API_BASE}/address/find`,
        { postcode, housenumber: `${housenumber}${housenumberAddition || ""}` }
      );
      if (response.status === 200) {
        setAddressEditable(false);
        window.setFormValue("street", response.data.data.street);
        window.setFormValue("city", response.data.data.city);
      } else {
        setAddressEditable(true);
        window.setFormValue("street", null);
        window.setFormValue("city", null);
      }
      setAddressLoading(false);
    } catch (e) {
      console.error(e);
      setAddressEditable(true);
      window.setFormValue("street", null);
      window.setFormValue("city", null);
      setAddressLoading(false);
    }
  };

  const handleChangeInvoiceAddress = (address) => {
    MyService.setInvoiceAddress(address);
    setProfileData({...profileData, company:{ ...profileData.company, invoiceAddress: address}})

  }

  const handleChangePickupAddress = async (address) => {
    MyService.setPickupAddress(address);
    await setProfileData({...profileData, company:{ ...profileData.company, defaultPickupAddress: address}})
  }

  
  return (
    <div>
      <Helmet>
        <title>
          Mijn Account - Mijn Verzenden - één verzendplatform voor grote en kleine pakketten
        </title>
        <link rel="canonical" href="https://www.verzenden.nl/mijn/adresboek" />
        <meta
          name="description"
          content="Wat wil je vandaag verzenden? Kies uit onze betrouwbare verzendpartners!"
        />
      </Helmet>

      <Container className="container-content container-my mt-5">
        <MyMenuHeader />
        <Row>
          <Col>
            <h1>Bewerk profiel</h1></Col>
        </Row>
        <Row>

          <Col>
            {profileData.loading && <Loader />}
            {profileData.error &&               
          <div
            className="alert alert-warning alert-auto mt-5 text-center w-100"
            role="alert"
          >
                  Fout bij ophalen van het profiel. <a href="#0" onClick={getProfile}>probeer opnieuw</a>
          </div>}
            {!profileData.error && !profileData.loading && <Form
              onSubmit={onSubmit}
              initialValues={profileData}
              mutators={{
                setValue: ([field, value], state, { changeValue }) => {
                  changeValue(state, field, () => value);
                },
              }}
              render={({
                handleSubmit,
                form,
                submitting,
                pristine,
                values,
                valid,
              }) => {
                window.setFormValue = form.mutators.setValue;
                return (
                  <form onSubmit={handleSubmit} className="mt-3">

                    <div>
                      <Row>
                        <Col xs={12} sm={5}>
                          <div className="form-group">
                            <label>Voornaam</label>
                            <Field
                              name="firstName"
                              validate={requiredValidator}
                            >
                              {(props) => <FormInputText {...props} />}
                            </Field>
                          </div>
                        </Col>
                        <Col xs={12} sm={7}>
                          <div className="form-group">
                            <label>Achternaam</label>
                            <Field
                              name="lastName"
                              validate={requiredValidator}
                            >
                              {(props) => <FormInputText {...props} />}
                            </Field>
                          </div>
                        </Col>

                        <Col xs={12} sm={5}>
                          <div className="form-group">
                            <label>Bedrijfsnaam</label>
                            <Field name="company.name">
                              {(props) => <FormInputText {...props} />}
                            </Field>
                          </div>
                        </Col>
                        <Col xs={12} sm={7}>
                          <div className="form-group">
                            <label>E-Mailadres</label>
                            <Field
                              name="email"
                              type="email"
                              validate={emailValidator}
                              readonly
                            >
                              {(props) => <FormInputText {...props} />}
                            </Field>
                          </div>
                        </Col>
                      </Row>
                      
                      <Row className="mt-4">
                        <Col xs={12} className="text-right">
                          <button className="btn btn-primary" type="submit">opslaan</button>

                        </Col>
                      </Row>

                      
                    </div>
                  </form>
                );
              }}
            />
            }
          </Col>
        </Row>
        <Row>

          <Col sm={6}>
            <Row className="mt-4 p-4">
              <Col xs={12}>
                <h2 className="mb-2">
                  Standaard afhaaladres
                </h2>
              </Col>
              <Col xs={12} className="boxed-grey">
                {(profileData.company && !profileData.company.defaultPickupAddress)  && (
                  <div
                    className="alert alert-warning alert-auto text-center w-100"
                    role="alert"
                  >
                  Je hebt nog geen afhaaladres opgegeven!
                  </div>
                )}

                {(profileData && profileData.company) && (
                  <MyAddressBookSelector hideDefaultPickupAddress onChange={handleChangePickupAddress} value={(profileData.company && profileData.company.defaultPickupAddress)  ? profileData.company.defaultPickupAddress : null} returnFullAddress />
                )}
              </Col>

            </Row>
          </Col>

          <Col sm={6}>
            <Row className="mt-4 p-4">
              <Col xs={12}>
                <h2 className="mb-2">
                  Standaard factuuradres
                </h2>
              </Col>
              <Col xs={12} className="boxed-grey">
                {(profileData.company && !profileData.company.invoiceAddress)  && (
                  <div
                    className="alert alert-warning alert-auto text-center w-100"
                    role="alert"
                  >
                  Je hebt nog geen factuuradres opgegeven!
                  </div>
                )}

                {(profileData && profileData.company) && (
                  <MyAddressBookSelector hideDefaultPickupAddress onChange={handleChangeInvoiceAddress} value={(profileData.company && profileData.company.invoiceAddress)  ? profileData.company.invoiceAddress : null} returnFullAddress />
                )}
              </Col>
            </Row>
          </Col>
        </Row>
      
        
      </Container>
    </div>
  );
}

export default PageMyProfile;
