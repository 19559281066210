import React, { useState } from "react";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { Helmet } from "react-helmet";

import {NavLink, useHistory, useLocation} from 'react-router-dom'

import classNames from 'classnames';

import FormInputText from "../../components/order/Form/FormInputText";
import MyService from '../../services/MyService'
import { Form, Field } from "react-final-form";

import { requiredValidator, emailValidator, composeValidators } from "../../helpers/validators";

import "./PageLogin.scss";

// import iconLetter from "../assets/shipment-letter.svg";

function PageLogin() {
  const [loginStatus, setLoginStatus] = useState('new');
  const history = useHistory();

  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }

  const query = useQuery();
    
  const handleLogin = async (data) => {

    setLoginStatus('loading');
    const login = await MyService.userLogin(data.email, data.password);
    if(!login || login.error) {
      setLoginStatus('failed')
    } else {
      setLoginStatus('success')
      history.push('/mijn');
    }
  };
  

  return (
    <div>
      <Helmet>
        <title>
          Inloggen - verzenden.nl: één verzendplatform voor grote en kleine pakketten
        </title>
        <link rel="canonical" href="https://www.verzenden.nl/" />
        <meta name="description" content="Login op uw Verzenden.nl accounnt" />
      </Helmet>

      <Container className="mt-5 pt-5 container-content">
        <Row>

          <Col xs={12} sm={6}>
            <h1 className="text-green text-uppercase">Inloggen</h1>

            <Form
              onSubmit={handleLogin}

              render={({
                handleSubmit,
                form,
                submitting,
                pristine,
                values,
                valid,
              }) => {
                window.setFormValue = form.mutators.setValue;
                return (
                  <form onSubmit={handleSubmit} className="mt-3">
                    <Row>
                      <Col xs={12} >
                        {loginStatus && loginStatus === "failed" && (
                          <Row>
                            <Col xs={12}>
                              <div
                                className="alert alert-warning alert-auto mb-3 w-100"
                                role="alert"
                              >
                                Ongeldige login
                              </div>
                            </Col>
                          </Row>
                        )}

                        {query && query.get("m") === "logout" && (
                          <Row>
                            <Col xs={12}>
                              <div
                                className="alert alert-success alert-auto mb-3 w-100"
                                role="alert"
                              >
                                Je bent uitgelogd!
                              </div>
                            </Col>
                          </Row>
                        )}


                        {query && query.get("m") === "activated" && (
                          <Row>
                            <Col xs={12}>
                              <div
                                className="alert alert-success alert-auto mb-3 w-100"
                                role="alert"
                              >
                                Registratie succesvol! Je kan nu inloggen.
                              </div>
                            </Col>
                          </Row>
                        )}
                        <div className="form-group">
                          <label>E-Mailadres</label>
                          <Field name="email"  validate={composeValidators(
                            requiredValidator,
                            emailValidator
                          )}>
                            {(props) => <FormInputText  {...props} />}
                          </Field>
                        </div>
                      </Col>
                      <Col xs={12} >
                        <div className="form-group">
                          <label>Wachtwoord</label>
                          <Field name="password" validate={requiredValidator}>
                            {(props) => <FormInputText {...props}   type="password"/>}
                          </Field>
                        </div>
                      </Col>
                    </Row>
                

                    <Row>
                      <Col className="text-right">
                        <button
                          className={classNames("btn btn-primary", {
                            "btn-loading": loginStatus === 'loading' ,
                          })}
                          disabled={!valid}
                        >
                        inloggen
                        </button>
                      </Col>
                    </Row>
                  </form>
                )}} />
          </Col>
          <Col xs={12} md={6}>
            <h1 className="text-green text-uppercase">Registreren</h1>
            <br /><br />
            <p className="login__registertxt">
            Wil je regelmatig pakketten verzenden? Maak direct een account aan. Voordelen:<br /><br />

- koppel je webshop/TMS-systeem met onze API<br />
- boek en beheer je orders vanuit het adresboek<br />
- print makkelijk en snel adreslabels <br />
- alle facturen in één overzicht <br /><br />
              <br />
              <span className="text-right d-block w-100">
                <NavLink to="/registreer" className="btn btn-secondary">Maak een account</NavLink>.
              </span>
            </p>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default PageLogin;
