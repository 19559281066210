export const composeValidators = (...validators) => value =>
  validators.reduce((error, validator) => error || validator(value), undefined);

export const isWeightValidator = (val) => (
  !isNaN(val) && val > 0 ? undefined : "Geen geldig gewicht"
)
export  const max32kgValidator = (val) =>
  Number(val) > 31.5
    ? "het maximale gewicht via Verzenden.nl is <strong>31.5kg</strong>! Voor zwaardere pakketten kunt u <a href=\"/contact\">contact opnemen</a>."
    : undefined;

export  const requiredValidator = (val) =>
  !val || val.length === 0
    ? "dit veld is verplicht"
    : undefined;

export  const postcodeValidator = (val) => {

  const check = (/[1-9][0-9]{3} ?(?!sa|sd|ss)[a-z]{2}/).test(val.toLowerCase())
  return !check ? "geen geldige postcode" : undefined
}

export  const postcodeValidatorBe = (val) => {

  const check = (/[1-9][0-9]{3}/).test(val.toLowerCase())
  return !check ? "geen geldige postcode" : undefined
}

export  const houseNumberValidator = (val) => (
  !isNaN(val) && val > 0 ? undefined : "Geen geldig huisnummer"
)

export  const colliValidator = (val) => (
  !isNaN(val) && val > 0 ? undefined : "Geen geldig aantal"
)

export const emailValidator = (val) => (
  (!val || (/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/).test(val)) ? undefined : "ongeldig e-mailadres"  //eslint-disable-line
);
export const phoneValidator = (val) => (
  (val && val.length === 10 && !isNaN(val) && Number(val.substr(0,1)) === 0) || !val  ? undefined : "ongeldig telefoonnummer"
);