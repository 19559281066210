import React, { useState } from "react";
import classNames from "classnames";
import { Form, Field } from "react-final-form";
import {Row, Col} from 'react-bootstrap';

import "./FaqContactForm.scss";
import FormInputText from "../../order/Form/FormInputText";
import { requiredValidator, phoneValidator, emailValidator, composeValidators } from "../../../helpers/validators";
import FormInputTextArea from "../../order/Form/FormInputTextArea";
import Axios from "axios";

const FaqContactForm = () => {
  const [sendStatus, setSendStatus] = useState({status: 'new'});

  const onSubmit = async ({name, email, phonenumber, message}) => {
    setSendStatus({status: "sending"});
    try {
      const response = await Axios.post(
        `${process.env.CONF_API_BASE}/contact`,
        { name, email, phonenumber, message }
      );    

      if(response.status && response.status  === 200) {
        setSendStatus({status: "sent"});
        window.dataLayer.push({'category':'Conversie',action: "Ingestuurd", label: "Contactformulier"})

      } else {
        setSendStatus({status: "error"});
      }
    }catch(e) {
      setSendStatus({status: "error"});
    }
  }
  return (
    <div className="contactform mt-4">

      {sendStatus && sendStatus.status === "sent" && (
        <Row>
          <Col xs={12}>
            <div
              className="alert alert-success alert-auto mt-3 w-100"
              role="alert"
            >
                  Bedankt! We proberen uw bericht z.s.m. te beantwoorden!
            </div>
          </Col>
        </Row>
      )}

      {sendStatus && sendStatus.status === "error" && (
        <Row>
          <Col xs={12}>
            <div
              className="alert alert-warning alert-auto mt-3 w-100"
              role="alert"
            >
                  Er is een fout opgetreden bij het verzenden van je bericht. Probeer het opnieuw.
            </div>
          </Col>
        </Row>
      )}

      {sendStatus && (sendStatus.status === 'new' || sendStatus.status === 'sending' || sendStatus.status === 'error') && (
        <Form
          onSubmit={onSubmit}

          render={({
            handleSubmit,
            form,
            submitting,
            pristine,
            values,
            valid,
          }) => {
            window.setFormValue = form.mutators.setValue;
            return (
              <form onSubmit={handleSubmit} className="mt-3">
                <Row>
                  <Col xs={12}  sm={5} >
                    <div className="form-group">
                      <label>Naam</label>
                      <Field name="name" validate={requiredValidator}>
                        {(props) => <FormInputText {...props} />}
                      </Field>
                    </div>
                  </Col>
                  <Col xs={12} sm={5} lg={3}>
                    <div className="form-group">
                      <label>Telefoonnummer</label>
                      <Field name="phonenumber" validate={phoneValidator}>
                        {(props) => <FormInputText {...props} />}
                      </Field>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} sm={8}>
                    <div className="form-group">
                      <label>E-mailadres</label>
                      <Field name="email"                           
                        validate={composeValidators(
                          requiredValidator,
                          emailValidator
                        )}>
                        {(props) => <FormInputText {...props} />}
                      </Field>
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col xs={12} sm={12}>
                    <div className="form-group">
                      <label>Vraag</label>
                      <Field name="message"
                        validate={requiredValidator}>
                        {(props) => <FormInputTextArea rows={10} {...props} />}
                      </Field>
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col className="text-right">
                    <button
                      className={classNames("btn btn-primary", {
                        "btn-loading": sendStatus.status === 'sending' ,
                      })}
                      disabled={!valid}
                    >
                        verstuur
                    </button>
                  </Col>
                </Row>
              </form>
            )}} />
      )}
    </div>
  );
};


export default FaqContactForm;
