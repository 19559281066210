import React, { useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { Helmet } from "react-helmet";
import classNames from "classnames";

import "./OrderFlow.scss";
import Col from "react-bootstrap/Col";
import { useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import Axios from "axios";

function PageOrderOverview() {
  const {
    deliveryAddress,
    invoiceAddress,
    pickupAddress,
    parcel,
  } = useSelector((state) => state.order);

  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);

  const createOrder = async () => {
    try {
      const base =
        pickupAddress && parcel.offerProvider === "Swift"
          ? { pickupAddress: { ...pickupAddress, country: deliveryAddress.pickupAddress ||  "NL" } }
          : {};
      const response = await Axios.post(`${process.env.CONF_API_BASE}/order`, {
        ...base,
        dropoffAddress: {
          ...deliveryAddress,
          country: deliveryAddress.country ||  "NL",
        },
        invoiceAddress: {
          ...invoiceAddress,
          country: invoiceAddress.country ||  "NL",
        },
        dimensions: { weight: parcel.weight },
        colli: parcel.colli,
        offer: parcel.offerId,
      });
      const { id } = response.status === 201 ? response.data : false;
      return id;
    } catch (e) {
      return false;
    }
  };

  const onConfirm = async () => {
    setIsLoading(true);
    const orderId = await createOrder();
    if (orderId) {
      history.push(`/verzenden/betaling/${orderId}`);
    }
    setIsLoading(false);
  };

  const AddressBlock = ({ address }) => (
    address ? 
      <>
    
        {`${address.firstName} ${address.lastName}`}

        {address.companyName && (
          <>
            <br />
            {address.companyName}
          </>
        )}
        <br />
        <br />
        {`${address.street} ${address.housenumber}${
          address.housenumberAddition || ""
        }`}
        <br />
        {`${address.postcode} ${address.city}`}
        {address.country === 'BE' && (<><br />België</>)}
        {address.note && (
          <>
            <br />
            <span className="order__subtitle">{address.note}</span>
          </>
        )}
        <br />
        <br />
        {address.phoneNumber}
        <br />
        {address.email}
      </> : <> </>
  );

  const isBelgium = (pickupAddress && pickupAddress.country === "BE") || (deliveryAddress && deliveryAddress.country === "BE");

  return (
    <div>
      <Helmet>
        <title>
          Controleer zending - verzenden.nl: één verzendplatform voor grote en
          kleine pakketten
        </title>
        <link
          rel="canonical"
          href="https://www.verzenden.nl/zending/controleer"
        />
      </Helmet>

      <Container className="mt-3 pt-5 ">
        <div className="container-content">
          <h1>Controleer zending</h1>
          {/* <span className="order__subtitle">
            Via Swift kan je ontvanger zelf een aflevermoment kiezen.
          </span> */}
          <Row className="mt-4">
            <Col sm={12}>
              <Row className="mt-4">
                <Col xs={12}>
                  <h2 className="mb-2 mx-4">
                    Pakket{" "}
                    <Link
                      to="/verzenden/pakket"
                      className="orderoverview--update"
                    >
                      wijzig
                    </Link>
                  </h2>
                </Col>
              </Row>
              <Row className="boxed-grey m-2">
                <Col xs={6} sm={3}>
                  <span>Vervoerder: </span>
                  <br />
                  <strong>{parcel.offerProvider}</strong>
                </Col>

                {/* <Col xs={6} sm={3}>
                  <span>Product: </span>
                  <br />
                  <strong>{parcel.offerName}</strong>
                </Col> */}

                <Col xs={6} sm={3}>
                  <span>gewicht: </span>
                  <br />
                  <strong>{parcel.weight}kg</strong>
                </Col>
                <Col xs={6} sm={3}>
                  <span>pakket(ten): </span>
                  <br />
                  <strong>{parcel.colli}</strong>
                </Col>
                <Col xs={6} sm={3}>
                  <span>Tarief: </span>
                  <br />
                  <strong>{(isBelgium ? parcel.rate + 25 : parcel.rate).toLocaleString('nl-NL', {
                    style: 'currency', 
                    currency: 'EUR', 
                    minimumFractionDigits: 2 
                  })}</strong>
                  {isBelgium && (
                    <><br /><small>incl &euro;25,00 België-toeslag</small></>
                  )}
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col sm={4}>
              <Row className="mt-4 p-4">
                <Col xs={12}>
                  <h2 className="mb-2">
                    Afleveradres
                    <Link
                      to="/verzenden/adres"
                      className="orderoverview--update"
                    >
                      wijzig
                    </Link>
                  </h2>
                </Col>
                <Col xs={12} className="boxed-grey">
                  <AddressBlock address={deliveryAddress} />
                </Col>
              </Row>
            </Col>

            {pickupAddress && parcel.offerProvider === "Swift" && (
              <Col sm={4}>
                <Row className="mt-4 p-4">
                  <Col xs={12}>
                    <h2 className="mb-2">
                      Afhaaladres
                      <Link
                        to="/verzenden/ophaaladres"
                        className="orderoverview--update"
                      >
                        wijzig
                      </Link>
                    </h2>
                  </Col>
                  <Col xs={12} className="boxed-grey">
                    <AddressBlock address={pickupAddress} />
                  </Col>
                </Row>
              </Col>
            )}

            <Col sm={4}>
              <Row className="mt-4 p-4">
                <Col xs={12}>
                  <h2 className="mb-2">
                    Factuuradres
                    <Link
                      to="/verzenden/factuuradres"
                      className="orderoverview--update"
                    >
                      wijzig
                    </Link>
                  </h2>
                </Col>
                <Col xs={12} className="boxed-grey">
                  {invoiceAddress.addressType === "custom" && (
                    <AddressBlock address={invoiceAddress} />
                  )}

                  {invoiceAddress.addressType === "pickup" && (
                    <AddressBlock address={pickupAddress} />
                  )}

                  {invoiceAddress.addressType === "dropoff" && (
                    <AddressBlock address={deliveryAddress} />
                  )}
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className="mt-5">
            <Col className="text-right">
              <button
                className={classNames("btn btn-primary", {
                  "btn-loading": isLoading,
                })}
                type="submit"
                onClick={onConfirm}
              >
                betaal
              </button>
            </Col>
          </Row>
        </div>
      </Container>
    </div>
  );
}

export default PageOrderOverview;
