export const renderOffers = (services, weight, colli) => {
  const offers = [];
  services.forEach(service => {
    const offer = service.offers.find(
      offer => weight >= offer.minWeight && weight < offer.maxWeight
    );
    if(offer){
      offers.push({ provider: service.provider, usps: service.usps, ...offer });
    }
  });
  return offers; 
};

export const MoneyFormat = (amount) => amount && !isNaN(amount) ? new Intl.NumberFormat('nl-NL', { style: 'currency', currency: 'EUR' }).format(amount) : 'n.b.'