
import React, { useEffect, useRef, useState } from "react";
import ClassNames from 'classnames';



import "./MyForms.scss";

function MyDropDown({data, specialData, value, onChange}) {

  const [selectedValue, setSelectedValue] = useState();
  const [highlighted, setHighlighted] = useState();
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [filteredData, setFilteredData] = useState([]);

  const setSelected = (value) => {
    onChange(value);
    setSelectedValue(value)
  }

  const renderSelectedItem = () => {
    const selectedData = data.find(item => item.value === value);
    if(selectedData) { 
      setSelectedValue(selectedData.value);
    } else {
      const selectedSpecialData = specialData.find(item => item.value === value);
      if(selectedSpecialData) { 
        setSelectedValue(selectedSpecialData.value);
      }
    }
  }

  const navigateHighlighted = (direction) => {
    const index = highlighted ? filteredData.findIndex(item => item.value === highlighted) : false ;

    if(direction === 'up') {
      if (index > 0) {
        setHighlighted(filteredData[index-1].value);
      }
    } else {
      if (index < filteredData.length - 1) {
        setHighlighted(filteredData[index+1].value);
      }
    }
  }

  const searchRef = useRef()
  const handleSearchTouch = e => {
    if(e.nativeEvent.key === 'ArrowDown') {
      e.preventDefault();
      navigateHighlighted('down');
    } else  if(e.nativeEvent.key === 'ArrowUp') {
      navigateHighlighted('up');
      e.preventDefault();

    } else  if(e.nativeEvent.key === 'Enter') {
      e.preventDefault();
      setSelected(highlighted);
      searchRef.current.blur();

    }
  }

  const handleSearch  = e => {
    const search = e.target.value; 
    if(search) {
      const result = data.filter(item => (item.searchTerms ? String(item.searchTerms).toLowerCase().indexOf(String(search).toLowerCase()) > -1 : false));
      setFilteredData(result);
      setHighlighted(result.length ? result[0].value : null)
    }else {
      setFilteredData(data);
      setHighlighted(null)
    }
  }

  const RenderSelected = () => { 
    const result = data.find(item => item.value === selectedValue) || specialData.find(item => item.value === selectedValue);
    if(result && result.name) {
      return <span className="mydropdown__selected"><strong>{result.name}</strong> - {result.description}</span>

    } else {
      return <span className="mydropdown__selected">{selectedValue || 'Zoek in adresboek...'}</span>

    }
  }

  const resetFilter = () => {setFilteredData(data)}
  useEffect(resetFilter, [data])

  useEffect(renderSelectedItem, [value, data, specialData]);

  return (
    <div className="mydropdown">

      <RenderSelected />

      <input 
        type="text" 
        className="mydropdown__search" 
        onChange={handleSearch} 
        placeholder="zoek in adresboek.." 
        onFocus={() => setDropdownOpen(true)} 
        onBlur={() => setTimeout(() => {setDropdownOpen(false)},100)} 
        onKeyDown={handleSearchTouch}
        ref={searchRef}
      />

      <div className={ClassNames("mydropdown__list", {"mydropdown__list--open": dropdownOpen})}>
        {filteredData.map(item => (
          <div 
            className={ClassNames("mydropdown__list__item", {"mydropdown__list__item--highlighted": highlighted === item.value})} key={item.value}
            onMouseOver={() => setHighlighted(item.value)}
            onClick={(e) => {e.preventDefault(); setSelected(item.value)}}
          >
            <strong>{item.name}</strong> - {item.description}
          </div>
        ))}
      </div>
    </div>
  );
}

export default MyDropDown;
