import React from "react";
import Container from "react-bootstrap/Container";
import { Helmet } from "react-helmet";

import "./FaqList.scss";
import FaqContactForm from "../../components/faq/FaqContactForm/FaqContactForm";
import { NavLink } from "react-router-dom";

// import iconLetter from "../assets/shipment-letter.svg";

function PageContact() {

  return (
    <div>
      <Helmet>
        <title>
          Contact - verzenden.nl: één verzendplatform voor grote en kleine pakketten
        </title>
        <link rel="canonical" href="https://www.verzenden.nl/" />
        <meta name="description" content="Veelgestelde vragen &amp; contact" />
      </Helmet>

      <Container className="mt-5 pt-5 container-content">
        <h1 className="" >Contactformulier</h1>
        <span className="order__subtitle">Staat uw antwoord niet op onze pagina met <NavLink to="/faq">veelgestelde vragen</NavLink>, dan helpen we u graag via onderstaand contactformulier.</span>
        <FaqContactForm  />
      </Container>
    </div>
  );
}

export default PageContact;
