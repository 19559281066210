import React, { useState } from "react";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import "./HeaderMenuComponent.scss";
import logo from "../../assets/verzenden-logo.svg";
import ClassNames from "classnames";
import { NavLink, useLocation } from "react-router-dom";
import { useEffect } from "react";

import MyService from '../../services/MyService';




function HeaderMenuComponent() {
  const [menuIsVisible, setMenuIsVisible] = useState(false);
  const location = useLocation();
  const userData = location.pathname === "/login"  ? {} : MyService.getUserDetails();



  useEffect(() => {
    setMenuIsVisible(false)
  }, [location]);
  
  return (
    <header
      className={ClassNames("headermenu", {
        "headermenu--env": process.env.NODE_ENV !== "production",
      })}
    >
      <Container>
        <Row>
          <Col sm={3}>
            <NavLink
              to="/"
              exact
            >
              <img src={logo} className="headermenu__logo" alt="logo" />
            </NavLink>

          </Col>
          <Col sm={4} className="d-lg-none headermenu__hamburger">
            <button className={ClassNames("hamburger hamburger--squeeze", {'is-active': menuIsVisible})} type="button"
              onClick={() => {setMenuIsVisible(!menuIsVisible)}}>
              <span className="hamburger-box">
                <span className="hamburger-inner"></span>
              </span>
            </button>
          </Col>
          <Col sm={9} className="text-right">
            <ul className={ClassNames("headermenu__menu d-sm-block", {'open': menuIsVisible})}>
              <li>
                
                {userData && userData.id ? (<NavLink
                  activeClassName="headermenu__menu__item--selected"
                  to="/mijn/orders/nieuw"
                >
                  verzenden
                </NavLink>)  : 

                  (<NavLink
                    activeClassName="headermenu__menu__item--selected"
                    to="/verzenden"
                  >
                  verzenden
                  </NavLink>)
                }
              </li>
              {/* <li>
                <NavLink
                  activeClassName="headermenu__menu__item--selected"
                  to="/zakelijk"
                >
                  voor bedrijven
                </NavLink>{" "}
              </li> */}
              <li>
                <NavLink
                  activeClassName="headermenu__menu__item--selected"
                  to="/faq"
                >
                  veelgestelde vragen
                </NavLink>{" "}
              </li>
              <li>
                <NavLink
                  activeClassName="headermenu__menu__item--selected"
                  to="/contact"
                >
                  contact
                </NavLink>{" "}
              </li>

              {userData && userData.id ?
                <li className="headermenu__menu__item--login headermenu__menu__item--loggedin">
                  <NavLink to="/mijn">{`${userData.firstName} ${userData.prefix || ''} ${userData.lastName}`}</NavLink>
                </li>
                : 
                <li className="headermenu__menu__item--login">
                  <NavLink to="/login">inloggen</NavLink>
                </li>  }

              
            </ul>
          </Col>
        </Row>
      </Container>
      <div className="headermenu__topbar"></div>
      {process.env.NODE_ENV !== "production" && (
        <div className="headermenu__envbar">
          env: <strong>{process.env.CONF_ENV_TITLE}</strong>
        </div>
      )}
    </header>
  );
}

export default HeaderMenuComponent;
