
import React, { useEffect, useState } from "react";

import MyService from "../../../services/MyService";
import MyDropDown from "./MyDropDown";

import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

import {
  composeValidators,
  requiredValidator,
  postcodeValidator,
  postcodeValidatorBe,
  houseNumberValidator,
  emailValidator,
} from "../../../helpers/validators";
import FormInputText from "../../../components/order/Form/FormInputText";
import FormInputTextArea from "../../../components/order/Form/FormInputTextArea";
import { Form, Field } from "react-final-form";
import classNames from "classnames";

import "./MyForms.scss";
import Loader from "../../Loader/Loader";

const axios = require("axios");

function MyAddressBookSelector({hideDefaultInvoiceAddress, hideDefaultPickupAddress, value, onChange, returnFullAddress, compactAddress, readOnly}) {
  const [addresBookData, setAddressBookData] = useState([]);
  const [defaultAddressData, setDefaultAddressData] = useState([]);
  const [selectedData, setSelectedData] = useState(null)
  const [rawData, setRawData] = useState([]);
  const [addressEditable, setAddressEditable] = useState(false);
  const [addressLoading, setAddressLoading] = useState(false);
  const [customAddressEditable, setCustomAddressEditable] = useState(false);
  const [newValue, setNewValue] = useState()
  const [manualAddressInput, setManualAddressInput] = useState(false);
  const toggleManualAddressInput = (e) => {e.preventDefault(); setManualAddressInput(!manualAddressInput)}


  const renderAddressDropdownData = (address) => (
    address ? 
      {
        name: address.companyName || `${address.firstName} ${address.lastName}`,
        description: `${address.street} ${address.housenumber}${address.housenumberAddition || ''}, ${address.city}`,
        searchTerms: `${address.companyName||''} ${address.firstName} ${address.lastName} ${address.street} ${address.city}`,
        value: address.id
      } : {}
  )

  const getAddresses = () =>  Promise.all([MyService.getProfile(), MyService.getAddressbook()]);

  const setAddresses = async () => {
    const [profile, addressBook] = await getAddresses();
    await setRawData(addressBook.addresses);
    await setAddressBookData(addressBook.addresses.map(renderAddressDropdownData));
    await setDefaultAddressData([{...renderAddressDropdownData(profile.pickupAddress)}, {...renderAddressDropdownData(profile.invoiceAddress)}]);
    setValue(addressBook);
  }

  const setValue = (customRawData = null) => {
    const addressData = customRawData || rawData;
    if(typeof value === "string") {

      if(addressData && addressData.length){
        const data = addressData.find(item => item.id === (value))
        setSelectedData(data);
      }
    
    } else if (value && value.street) {
      setSelectedData(value);
    }
  }

  const handleEditAddress = (e) => {
    e.preventDefault();
    setAddressEditable(true);
  }
  useEffect(() => {setAddresses()}, [value])

  const confirmAddress = (address = null) => {
    onChange(address || newValue);
    setAddressEditable(false);
    const displayAddress = (typeof (address || newValue) === "string") ? rawData.find(item => item.id === (address || newValue)) : (address || newValue);
    setSelectedData(displayAddress);
  }

  const handleNewAddress = (val) => {
    setNewValue((returnFullAddress && typeof val === "string") ? rawData.find(item => item.id === val) : val);
  }

  const getAddressByPostcode = async (
    postcode,
    housenumber,
    housenumberAddition
  ) => {
    if (!postcode || !housenumber) {
      setAddressLoading(false);
      return false;
    }

    try {
      setAddressLoading(true);
      const response = await axios.post(
        `${process.env.CONF_API_BASE}/address/find`,
        { postcode, housenumber: `${housenumber}${housenumberAddition || ""}` }
      );
      if (response.status === 200) {
        setCustomAddressEditable(false);
        window.setFormValue("street", response.data.street);
        window.setFormValue("city", response.data.city);
      } else {
        setCustomAddressEditable(true);
        window.setFormValue("street", null);
        window.setFormValue("city", null);
      }
      setAddressLoading(false);
    } catch (e) {
      console.error(e);
      setCustomAddressEditable(true);
      window.setFormValue("street", null);
      window.setFormValue("city", null);
      setAddressLoading(false);
    }
  };

  const AddressBlock = ({ address }) => (
    address ? 
      <>
        {`${address.firstName} ${address.lastName}`}
        {address.companyName && (
          <>
            <br />
            {address.companyName}
          </>
        )}
        <br />
        <br />
        {`${address.street} ${address.housenumber}${
          address.housenumberAddition || ""
        }`}
        <br />
        {`${address.postcode} ${address.city}`}
        {address.country === 'BE' && (<><br />België</>)}

        {address.note && (
          <>
            <br />
            <span className="order__subtitle">{address.note}</span>
          </>
        )}

        { address.phoneNumber&& (
          <>       
            <br />
            <br />
            {address.phoneNumber}
          </>
        )}
        <br />
        {address.email}
      </> : <> </>
  );

  const CompactAddressBlock = ({ address }) => (
    address ? 
      <>
        <strong>
          {address.companyName ? address.companyName : `${address.firstName} ${address.lastName}`}
        </strong>
        <br />
        {`${address.street} ${address.housenumber}${
          address.housenumberAddition || ""
        }`}
        <br />
        {`${address.postcode} ${address.city}`}
        {address.country === 'BE' && (<><br />België</>)}

      </> : <> </>
  );
  

  return (
    <div>
      {((!selectedData) || (selectedData && addressEditable)) && !readOnly  && <>
        {!manualAddressInput && <> 
          <MyDropDown data={addresBookData} specialData={defaultAddressData} onChange={handleNewAddress} value />
          <Row>
            <Col className="text-left mt-3">
              <button
                className={classNames("btn btn-primary w-100")}
                disabled={!newValue}
                onClick={() => confirmAddress()}
              >
                bevestig adres
              </button>
            </Col>
          </Row>
          <div className="hr-text my-4"><span>of <a href="#1" onClick={toggleManualAddressInput}>vul handmatig in</a></span>
          </div>
        </>}

        {manualAddressInput && (<>
          <Form
            onSubmit={confirmAddress}
            mutators={{
              setValue: ([field, value], state, { changeValue }) => {
                changeValue(state, field, () => value);
              },
            }}

            render={({
              handleSubmit,
              form,
              submitting,
              pristine,
              values,
              valid,
            }) => {
              window.setFormValue = form.mutators.setValue;
              return (
                <form onSubmit={handleSubmit} className="mt-3">

                  <div>
                    <Row>
                      <Col xs={12} sm={5}>
                        <div className="form-group">
                          <label>Voornaam</label>
                          <Field
                            name="firstName"
                            validate={requiredValidator}
                          >
                            {(props) => <FormInputText {...props} />}
                          </Field>
                        </div>
                      </Col>
                      <Col xs={12} sm={7}>
                        <div className="form-group">
                          <label>Achternaam</label>
                          <Field
                            name="lastName"
                            validate={requiredValidator}
                          >
                            {(props) => <FormInputText {...props} />}
                          </Field>
                        </div>
                      </Col>

                      <Col xs={12} sm={12}>
                        <div className="form-group">
                          <label>Bedrijfsnaam</label>
                          <Field name="companyName">
                            {(props) => <FormInputText {...props} />}
                          </Field>
                        </div>
                      </Col>
                    </Row>

                    <Row>
                      <Col xs={12} sm={5}>
                        <div className="form-group">
                          <label>Telefoon</label>
                          <Field name="phoneNumber" type="tel">
                            {(props) => <FormInputText {...props} />}
                          </Field>
                        </div>
                      </Col>
                      <Col xs={12} sm={7}>
                        <div className="form-group">
                          <label>E-Mailadres</label>
                          <Field
                            name="email"
                            type="email"
                            validate={emailValidator}
                          >
                            {(props) => <FormInputText {...props} />}
                          </Field>
                        </div>
                      </Col>
                    </Row>

                    <Row className="mt-5 mb-2 pl-1">
                      <Col>
                        <h2>Adres</h2>
                      </Col>
                    </Row>

                    <Row>
                      <Col xs={12}>
                        <div className="form-group">
                          <label>land*</label><br />
                          <Field
                            name="country"
                            defaultValue="NL"
                            component="select"
                            className="form-control"
                          >
                            <option value="NL">Nederland</option>
                            <option value="BE">België</option>
                          </Field>
                        </div>
                      </Col>
                    </Row>

                    <Row>
                      <Col xs={6} sm={6}>
                        <div className="form-group">
                          <label>postcode*</label>
                          <Field
                            name="postcode"
                            validate={composeValidators(
                              requiredValidator,
                              values.country === 'BE' ?  postcodeValidatorBe : postcodeValidator
                            )}
                          >
                            {(props) => (
                              <FormInputText
                                {...props}
                                onBlur={(e) => {
                                  values.country === 'NL' &&  getAddressByPostcode(
                                    values.postcode,
                                    values.housenumber,
                                    values.housenumberAddition
                                  );
                                }}
                              />
                            )}
                          </Field>
                        </div>
                      </Col>
                      <Col xs={6} sm={3}>
                        <div className="form-group">
                          <label>huisnummer*</label>
                          <Field
                            name="housenumber"
                            type="number"
                            validate={composeValidators(
                              requiredValidator,
                              houseNumberValidator
                            )}
                          >
                            {(props) => (
                              <FormInputText
                                onBlur={(e) => {
                                  values.country === 'NL' &&  getAddressByPostcode(
                                    values.postcode,
                                    values.housenumber,
                                    values.housenumberAddition
                                  );
                                }}
                                {...props}
                              />
                            )}
                          </Field>
                        </div>
                      </Col>
                      <Col xs={7} sm={3}>
                        <div className="form-group">
                          <label>toevoeging</label>
                          <Field name="housenumberAddition">
                            {(props) => (
                              <FormInputText
                                onBlur={(e) => {
                                  values.country === 'NL' &&  getAddressByPostcode(
                                    values.postcode,
                                    values.housenumber,
                                    values.housenumberAddition
                                  );
                                }}
                                {...props}
                              />
                            )}
                          </Field>
                        </div>
                      </Col>
                    </Row>

                    <Row>
                      <Col xs={12} sm={6}>
                        <div className="form-group">
                          <label>Straat</label>
                          <Field name="street" validate={requiredValidator}>
                            {(props) => (
                              <FormInputText
                                {...props}
                                readonly={values.country === 'NL' && !customAddressEditable}
                                loading={addressLoading}
                              />
                            )}
                          </Field>
                        </div>
                      </Col>
                      <Col xs={12} sm={6}>
                        <div className="form-group">
                          <label>Plaats</label>
                          <Field name="city" validate={requiredValidator}>
                            {(props) => (
                              <FormInputText
                                {...props}
                                readonly={values.country === 'NL' && !customAddressEditable}
                                loading={addressLoading}
                              />
                            )}
                          </Field>
                        </div>
                      </Col>
                      <Col xs={12} >
                        <div className="form-group mt-3">
                          <label>Opmerkingen voor de bezorger</label>
                          <Field name="note">
                            {(props) => (
                              <FormInputTextArea
                                {...props}
                                rows={2}
                              />
                            )}
                          </Field>
                        </div>
                      </Col>
                      <Row>
                        <Col>
                          <div className="form-check">
                            <Field
                              name="saveToAddressBook"
                              component="input"
                              type="checkbox"
                              id="saveToAddressBook"
                              className="form-check-input"
                            >
                            </Field>
                            <label className="form-check-label" htmlFor="saveToAddressBook">
                              voeg toe aan adresboek
                            </label>
                          </div>
                        </Col>
                      </Row>
                    </Row>
                

                    <Row>
                      <Col className="text-left mt-3">
                        <button
                          className={classNames("btn btn-primary w-100")}
                          disabled={!valid}
                        >
                          bevestig adres
                        </button>
                      </Col>
                    </Row>
                  </div>
                </form>
              );
            }}
          />
          <div className="hr-text my-4"><span>of <a href="#1" onClick={toggleManualAddressInput}>kies uit adresboek</a></span></div>
        </>
        )}
      </> }
      {!selectedData && value && (<Loader noPadding />)}
      {selectedData && !addressEditable && (
        <>
          {compactAddress ? <CompactAddressBlock address={selectedData} /> : <AddressBlock address={selectedData} />}
          {!readOnly && <>
            <br /><br />
            <a href="#a" onClick={handleEditAddress}>wijzig</a>
          </>}
        </>)}
    </div>
  );
}

export default MyAddressBookSelector;
