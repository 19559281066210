import React, {useEffect, useState } from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import MyAddressBookSelector from "../forms/MyAddressBookSelector";

import "./MyOrderItems.scss";
import "../../../pages/order/OrderFlow.scss";
import { renderOffers, MoneyFormat } from "../../../helpers/shippingHelpers";

import IconAdd from "./IconAdd.svg";
import moment from 'moment';
import 'moment/locale/nl';
import MyService from "../../../services/MyService";
const axios = require("axios");

function MyOrderItemEdit({orderData, profile, onSave, clickToAdd, pickupAddress}) {

  const [orderItem, setOrderItem] = useState({productGroup: null})
  const [addingMode, setAddingMode] = useState(false);
  const [belgiumAddition, setBelgiumAddition] = useState(false);
  const [availablePickupDates, setAvailablePickupDates] = useState([])
  const [offerLoading, setOfferLoading] = useState(false); //eslint-disable-line

  const setDropoffAddres = async (address) => {
    let belgiumShipment = false;
    if(typeof address === 'string') {
      const getAddress = await MyService.getAddress(address);

      if(getAddress.country === 'BE') {
        belgiumShipment = true;
      }
    } else {
      if(address && address.country === 'BE') {
        belgiumShipment = true;
      }
    }
    
    if(belgiumShipment) {
      setBelgiumAddition(true)
    } else {
      setBelgiumAddition(false)
    }

    setOrderItem({...orderItem, dropoffAddress: address});
  }

  const getOfferSwift = async (weight, colli = 1) => {
    if(!weight || weight < 1) {
      return false;
    }
    try {
      setOfferLoading(true);
      const req = await axios.get(`${process.env.CONF_API_BASE}/rate?weight=${weight}&colli=${colli}`);
      const offer = req.data.rate;
      setOfferLoading(false);
      return offer;
    } catch (e) {
      console.error(e);
      setOfferLoading(false);
      return false;
    }
  };

  const getOfferPostNL = async (weight) => {
    if(!weight || weight < 1) {
      return false;
    }
    try {
      const services = await axios.get("/mock-services.json");
      const offer = renderOffers(services.data, weight).find(offer => offer.provider === 'PostNL').rate;
      return offer;
    } catch (e) {
      console.error(e);
      return false;
    }
  };

  const getAvailablePickupDates = async () => {
    if(pickupAddress && pickupAddress.postcode){
      const dates = await MyService.getPickupDates(pickupAddress.postcode)
      if(!dates.error) {
        setAvailablePickupDates(dates)
        setOrderItem({...orderItem, pickupDate: dates[0]});

        return;
      }
    } else if(pickupAddress && typeof pickupAddress === "string") {
      const address = await MyService.getAddress(pickupAddress);
      
      if(!address.error) {
        const dates = await MyService.getPickupDates(address.postcode);
        if(!dates.error) {
          setAvailablePickupDates(dates)
          setOrderItem({...orderItem, pickupDate: dates[0]});

          return;
        } else {
          setAvailablePickupDates([])
        }
      } else {
        setAvailablePickupDates([])
      }
    } else {
      setAvailablePickupDates([])
    } 
  }

  const calculatePrice = async (data) => {
    let amount;
    let belgiumShipment = false;
    if(!data) {
      return false;
    }

    if(data.productGroup === "swift" || !data.productGroup) {
      amount = await getOfferSwift(data.weight || 0, data.colli || 1);
    } else if(data.productGroup === "postnl") {
      amount = await getOfferPostNL(data.weight || 0);
    } else {
      const selectedGroup = profile.company ? profile.company.productGroups.data.find(group => group.key === data.productGroup) : {amount: 0};
      amount = selectedGroup.amount * (data.colli || 1);
    } 
    if(pickupAddress.country === 'BE') {      
      belgiumShipment = true;
    }
    if(typeof orderItem.dropoffAddress === 'string') {
      const address = await MyService.getAddress(orderItem.dropoffAddress);
      if(address.country === 'BE') {
        belgiumShipment = true;
      }
    }
    if(data.dropoffAddress && data.dropoffAddress.country === 'BE') {
      belgiumShipment = true;
    }
    
    if(belgiumShipment) {
      setBelgiumAddition(true)
      amount = +amount + 25;
    } else {
      setBelgiumAddition(false)
    }

    setOrderItem({...orderItem, dimensions: {weight: data.weight}, productGroup: data.productGroup, amount, colli: data.colli});
  }

  const handleChangeProductgroup = async (event) => {
    const productGroup = event.target.value;
    await setOrderItem({...orderItem, productGroup});
    if((productGroup === "swift" || productGroup === "postnl") && orderItem.dimensions && orderItem.dimensions.weight) {
      calculatePrice({productGroup, weight: orderItem.dimensions.weight})
    } else {
      calculatePrice({productGroup})

    }
  }

  const handleChangeWeight = async (event) => {
    const weight = event.target.value;
    await setOrderItem({...orderItem, dimensions: {weight}});
    calculatePrice({productGroup: orderItem.productGroup, colli: orderItem.colli, weight})
  }

  const handleChangeColli = async (event) => {
    const colli = event.target.value;
    await setOrderItem({...orderItem, colli});
    calculatePrice({productGroup: orderItem.productGroup, weight: orderItem.dimensions.weight, colli})
  }

  const handleChangeInput = (event) => {
    const {name, value} = event.target;
    const objToAdd = {};
    objToAdd[name] = value;
    setOrderItem({...orderItem, ...objToAdd});
  }

  const handleSave = () => {
    if(!orderItem.productGroup) {
      orderItem.productGroup = 'swift';
    }
    onSave(orderItem);
    setAddingMode(false)
    setOrderItem({reference: '', article: '', productGroup: null, dropoffAddress: null})
  }

  useEffect(() => calculatePrice, [orderData])
  useEffect((e) => {getAvailablePickupDates()}, [pickupAddress])

  return (
    <>
      {(clickToAdd && !addingMode) && (
        <div className="myorderitem myorderitem--addingmode">
          <button onClick={() => {setAddingMode(true)}}>
            <img src={IconAdd} alt="+" />
            <span>voeg zending toe</span>
          </button>
        </div>

      )}
      {(!Boolean(clickToAdd) || !!addingMode) && (
        <div className="myorderitem">
          
          {(orderItem && orderItem.productGroup && orderItem.productGroup  === "postnl") && ( 
            // <div className="myorderitem p-0 m-0 mb-4">
            <div
              className="alert alert-warning alert-auto text-center w-100 m-0 mb-4"
              role="alert"
            >
              Een PostNL-zending kan helaas niet bij u afgehaald worden. U kunt deze zelf afgeven bij een <a href="https://www.postnl.nl/locatiewijzer/" target="_blank" rel="noreferrer">PostNL-punt</a>.
            </div>
            // </div>
          )}
          <Row>
            <Col xs={12} md={5} className="pr-4">
              <h2 className="mb-3">Afleveradres</h2>
              <MyAddressBookSelector onChange={setDropoffAddres} value={orderItem && orderItem.dropoffAddress} />
            </Col>
            <Col xs={12} md={5} className="pr-4">
              {profile.company && profile.company.productGroups && profile.company.productGroups.data && (
                <>
                  <h2 className="mb-3">Zending</h2>
                  {profile.company.productGroups && <select value={(orderItem && orderItem.productGroup) || "swift"} className="w-100 form-control" onChange={handleChangeProductgroup}>
                    <option value="swift">Swift Standaard - op gewicht</option>
                    {profile.company.productGroups.data.map(group => (
                      <option value={group.key} key={group.key}>{`${group.name} -  ${MoneyFormat(group.amount)}`}</option>
                    ))}
                  </select>}
                  {!profile.company.productGroups && (
                    `Swift Standaard - op gewicht`
                  )}
                </>
              )}

              <Row>
                {orderItem && (!orderItem.productGroup || orderItem.productGroup === "swift" || orderItem.productGroup === "postnl") && (
                  <Col xs={6}>
                    <div className="form-group mt-3">
                      <label>Gewicht</label>
                      <div className="input-group" style={{ width: 180 }}>
                        <input
                          name="weight"
                          type="text"
                          className="form-control"
                          onChange={handleChangeWeight}
                        />
                        <div className="input-group-append">
                          <div className="input-group-text">kg</div>
                        </div>
                      </div>
                    </div>
                  </Col>
                )}

                <Col xs={6}>

                  <div className="form-group mt-3">
                    <label>Colli</label>
                    <div className="input-group" style={{ width: 180 }}>
                      <input
                        name="weight"
                        type="text"
                        className="form-control"
                        onChange={handleChangeColli}
                      />
                      <div className="input-group-append">
                        <div className="input-group-text">stuk(s)</div>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>


              <div className="form-group mt-3">
                <label>Referentie</label>
                <div className="input-group w-100">
                  <input
                    name="reference"
                    type="text"
                    className="form-control"
                    onChange={handleChangeInput}
                    maxLength='100'
                    value={orderItem && orderItem.reference}
                  />
                </div>
                <Row className="mt-3">
                  <Col xs={12}>              
                    <div className="form-group">
                      <label>Afhaaldatum</label>
                      <select 
                        name="pickupDate"
                        onChange={handleChangeInput}
                        className="form-control"
                      >
                        {!availablePickupDates.length && <option disabled>geen opties voor deze postcode</option>}
                        {availablePickupDates.map((date, i) => (
                          <option key={date} value={date}>{moment(date).format('dddd -  LL')}</option>
                        ))}
                      </select>
                    </div>
                  </Col>
                  {/* <Col xs={6}>              

                    
                    <div>
                      <label>Tijdvak</label>
                      <div className="form-check">
                        <input  className="form-check-input" 
                          type="radio" 
                          id="pickupWindow1" 
                          value="ochtend" 
                          name="pickupDayPart"
                          onChange={handleChangeInput}

                          // checked={input.checked}
                          // onChange={input.onChange}
                        />
                        <label className="form-check-label" htmlFor="pickupWindow1">
                                09:00 - 14:00
                        </label>
                      </div>
                         
                      <div className="form-check">
                        <input className="form-check-input" 
                          type="radio" 
                          id="pickupWindow2" 
                          value="middag" 
                          name="pickupDayPart"
                          onChange={handleChangeInput}

                          // checked={input.checked}
                          // onChange={input.onChange}
                        />
                        <label className="form-check-label" htmlFor="pickupWindow2">
                                13:00 - 18:00
                        </label>
                      </div>

                      <div className="form-check">
                        <input className="form-check-input" 
                          type="radio" 
                          id="pickupWindow3" 
                          value="whatever" 
                          name="pickupDayPart"
                          onChange={handleChangeInput}

                          // checked={input.checked}
                          // onChange={input.onChange}
                        />
                        <label className="form-check-label" htmlFor="pickupWindow3">
                                geen voorkeur
                        </label>
                      </div>
                           
                          
                    </div>
                  </Col> */}
                </Row>
              </div>


            </Col>
            <Col xs={12} md={2} className="d-flex flex-column myorderitem__price-container">
              <h2 className="mb-3  align-self-start mb-auto text-center w-100">Tarief</h2>
              <span className="myorderitem__price align-self-end d-block w-100 text-center">{MoneyFormat(orderItem && orderItem.amount)}</span>
              {belgiumAddition && <small className="w-100 text-center">incl. €25 België-toeslag</small>}
              <button className="btn btn-secondary myorderitem__btn_add_order" onClick={handleSave} disabled={!(orderItem && orderItem.amount && orderItem.dropoffAddress)}>voeg toe</button>
            </Col>
          </Row> 
        </div>
      )}
    </>
  );
}

export default MyOrderItemEdit;
