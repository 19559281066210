import React from "react";

import "./FooterComponent.scss";
import Container  from "react-bootstrap/Container";
import { Row } from "react-bootstrap";
import { Col } from "react-bootstrap";

function FooterComponent() {
  return (
    <footer className=" pt-5">
      <Container>
        <Row>
          <Col xs={12} sm={4} className="footer__links">
            <a href="/docs/avc.pdf" target="_blank">algemene voorwaarden</a>
           &nbsp; &nbsp; &nbsp;
            <a href="/docs/privacy-policy.pdf" target="_blank">privacy policy</a>
          </Col>
          <Col xs={12} sm={8} className="text-sm-right footer__copyright">
        Verzenden.nl is een onderdeel van Swift MEGA Logistics B.V. (KVK 78346673)
          </Col>
        </Row>
      </Container>
    </footer>
  );
}

export default FooterComponent;
